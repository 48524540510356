import React from "react"
import DropoutEmployeeTable from "../../Components/Tables/EmployeeTables/DropoutEmployeeTable"

function DropoutEmployee ( ) {
  return (
    <DropoutEmployeeTable/>
  )
}

export default DropoutEmployee
