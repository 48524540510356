import React, { useEffect, useState } from "react"
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    TableBody,
    Button,
    Stack,
    Chip,
} from "@mui/material"
import {
    fetchAllEmployeeCounts,
    fetchAllEmployeeRecords,
} from "../../../Actions/commonController"
import SearchBar from "../../SearchBar"
import PaginationTable from "../../Pagination"
import { useAuth } from "../../../Context/authContext"
import { toast } from "react-toastify"

import PersonalDetailsModal from "../../Modals/PersonalDetailsModal"
import EmployeeDetailsModal from "../../Modals/EmployeeDetailsModal"
import JoiningDetailsModal from "../../Modals/JoiningDetailsModal"
import VisaDetailsModal from "../../Modals/VisaDetailsModal"
import ContactDetailsModal from "../../Modals/ContactDetailsModal"
import DocumentDetailsModal from "../../Modals/DocumentDetailsModal"

const columns = [
    { id: "Id", label: "EMPID", minWidth: 40 },
    { id: "name", label: "NAME", minWidth: 110 },
    { id: "passportno", label: "PASSPORT NO.", minWidth: 110 },
    { id: "empstatus", label: "STATUS", minWidth: 110 },
    { id: "personalDetails", label: "PERSONAL DETAILS", minWidth: 80 },
    { id: "employeeDetails", label: "EMPLOYEE DETAILS", minWidth: 80 },
    { id: "joiningDetails", label: "JOINING DETAILS", minWidth: 80 },
    { id: "visaDetails", label: "VISA DETAILS", minWidth: 80 },
    { id: "contactDetails", label: "CONTACT DETAILS", minWidth: 80 },
    { id: "documentDetails", label: "DOCUMENT DETAILS", minWidth: 80 },
]

const actionButtonStyle = {
    margin: "5px",
    fontSize: "10px",
}
export default function AllEmployeeTable() {

    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [selectedData, setSelectedData] = useState(null)
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [detailsModalOpen, setDetailsModalOpen] = useState("")
    const [loader, setLoader] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)

    const { logout } = useAuth()


    const handleSearch = async (searchData, type) => {
        setSearchTerm(searchData)
    }

    const initialization = async () => {
        try {
            let APIData = {
                search: searchTerm,
            }
            setLoader(true)
            let { status, data } = await fetchAllEmployeeCounts(APIData, logout)

            if (status === "success") {
                setTotalItems(data)
                loadData()
            }
        } catch (err) {
            console.error("Error in loadData function", err)
        }
    }

    const loadData = async () => {
        try {
            let APIData = {
                quantity: quantity,
                page: page,
                search: searchTerm,
            }

            setLoader(true)
            let { status, data } = await fetchAllEmployeeRecords(APIData, logout)
            if (status === "success") {
                setData(data)
            }
            else {
                console.error("Error fetching employee records:", status)
            }
        } catch (error) {
            console.error("Error in loadData function:", error)
        }
        setLoader(false)
    }

    const handlePageClick = async (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = async (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    const handleButtonClick = async (columnId, empId) => {
        setSelectedData(empId)
        setDetailsModalOpen(columnId)
    }

    const handleCloseModal = () => {
        setSelectedData(null)
        setDetailsModalOpen("")
    }

    const chipColor = (empstatus) => {
        switch (empstatus) {
            case "New":
                return "primary"
            case "Active":
                return "success"
            case "Inactive":
                return "error"
            case "Dropout":
                return "warning"
            default:
                return "default"
        }
    }

    useEffect(() => {
        if (initialLoad) loadData()
        setInitialLoad(true)
    }, [page, quantity])

    useEffect(() => {
        initialization()
    }, [searchTerm])


    return (
        <Paper className="pagePaper">
            <span className="info">
                <h5>All Employees' Information</h5>
                <SearchBar searchText={"Search by name..."} onSearch={handleSearch} searchType={"name"} />
                <SearchBar searchText={"Search by empid..."} onSearch={handleSearch} searchType={"empid"} />
                <SearchBar searchText={"Search by passport no..."} onSearch={handleSearch} searchType={"passportno"} />
            </span>

            <div className="row" style={{ paddingTop: "60px" }}>
                <div className="col-md-12">
                    <TableContainer
                        sx={{
                            overflowY: "auto",
                            maxHeight: "500px"
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            className="tableHeader"
                                            key={column.id}
                                            align="center"
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    loader ? (
                                        <TableRow>
                                            <TableCell colSpan={10} style={{ padding: "30px 0", textAlign: "center" }}>
                                                <CircularProgress size={100} />
                                            </TableCell>
                                        </TableRow>
                                    ) : data && data.length > 0 ? (
                                        data.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" className="p-0">{item.empid}</TableCell>
                                                <TableCell align="center" className="p-0">{item.name}</TableCell>
                                                <TableCell align="center" className="p-0">{item.passportno}</TableCell>
                                                {/* <TableCell align="center" className="p-0">{item.empstatus}</TableCell> */}
                                                <TableCell>
                                                    <Stack align="center" className="p-0" direction="row" spacing={1}>
                                                        <Chip label={item.empstatus} color={chipColor(item.empstatus)} />
                                                    </Stack>
                                                </TableCell>
                                                {columns.slice(4, columns.length).map((column) => (
                                                    <TableCell className="p-0" key={column.id} align="center">
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => handleButtonClick(column.id, item.empid)}
                                                            style={{
                                                                ...actionButtonStyle,
                                                                backgroundColor: "#2596BE",
                                                                fontSize: "10px",
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={10} style={{ padding: "30px 0", textAlign: "center" }}>
                                                No results found for "{searchTerm}"
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <PaginationTable
                        totalItems={totalItems}
                        itemsPerPage={quantity}
                        onPageChange={handlePageClick}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />

                </div>
            </div>

            {/* Rendering Modals with their selected row data */}
            <PersonalDetailsModal
                open={detailsModalOpen === "personalDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <EmployeeDetailsModal
                open={detailsModalOpen === "employeeDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <JoiningDetailsModal
                open={detailsModalOpen === "joiningDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <VisaDetailsModal
                open={detailsModalOpen === "visaDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <ContactDetailsModal
                open={detailsModalOpen === "contactDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <DocumentDetailsModal
                open={detailsModalOpen === "documentDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

        </Paper>
    )
}
