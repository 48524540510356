import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import { CircularProgress } from "@mui/material"
import { activeEmp, rejectEmp } from "../../Actions/commonController"
import { toast } from "react-toastify"
import ResponsiveDatePicker from "../DatePicker/ResponsiveDatePicker"
import dayjs from "dayjs"

export default function ConfirmationModal({ open, onClose, message, empId, email, type }) {
  const [loading, setLoading] = useState(false)
  const [dateofjoin, setdateofjoin] = useState(null)
  const [reason, setReason] = useState("")

  const handleYesClick = async () => {
    setLoading(true)
    if (type === "accept") {
      if (!dateofjoin) {
        toast.error("Please enter the Date of Join before proceeding.")
        setLoading(false)
        return
      }
      await hireEmployee()
    } else if (type === "reject") {
      if (!reason.trim()) {
        toast.error("Please provide a reason for rejection.")
        setLoading(false)
        return
      }
      await rejectEmployee()
    }
    setLoading(false)
  }

  const hireEmployee = async () => {
    try {
      let APIData = {
        empid: empId,
        dateofjoin: dateofjoin,
      }
      let { status } = await activeEmp(APIData)
      if (status === "success") {
        toast.success("Employee successfully activated!")
        onClose("success")
      } else {
        toast.error("Error moving employee! (Contact administrator)")
      }
    } catch (err) {
      toast.error("Error in server! (Contact administrator)")
    }
  }

  const rejectEmployee = async () => {
    try {
      let APIData = {
        empid: empId,
        reason: reason,
      }
      let { status } = await rejectEmp(APIData)
      if (status === "success") {
        toast.success("Employee rejected!")
        onClose("success")
      } else {
        toast.error("Error moving employee! (Contact administrator)")
      }
    } catch {
      toast.error("Error in server! (Contact administrator)")
    }
  }

  return (
    <Dialog open={open} onClose={() => { onClose("") }} aria-labelledby="responsive-dialog-title" maxWidth="xs">
      <DialogTitle id="responsive-dialog-title">{message.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message.body}</DialogContentText>
        {type === "accept" && (
          <div style={{ marginTop: '16px', textAlign: "center" }}>
            <ResponsiveDatePicker
              name="dateofjoin"
              label="Date of Join (Actual)"
              defValue={dateofjoin ? dayjs(dateofjoin).format("DD/MM/YYYY") : ""}
              handleDate={(event) => {
                const selectedDate = event.target.value

                if (selectedDate && dayjs(selectedDate, "DD/MM/YYYY", true).isValid()) {
                  const formattedDate = dayjs(selectedDate, "DD/MM/YYYY").format("YYYY-MM-DD")
                  setdateofjoin(formattedDate)
                } else {
                  setdateofjoin(null)
                }
              }}
            />
          </div>
        )}
        {type === "reject" && (
          <TextField
            label="Reason"
            variant="outlined"
            fullWidth
            margin="dense"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            multiline
            rows={3}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
        <Button
          style={{
            backgroundColor: "#FF0000",
            color: "white",
            width: "60px",
            height: "35px",
          }}
          autoFocus
          onClick={() => { onClose("") }}
          disabled={loading}
        >
          No
        </Button>
        <Button
          sx={{
            backgroundColor: 'primary.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
            width: '60px',
            height: '35px',
          }}
          onClick={handleYesClick}
          autoFocus
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Yes"}

        </Button>

      </DialogActions>
    </Dialog>
  )
}