import React, { useEffect, useState } from "react"
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Modal,
  Typography,
  Stack,
  Popover,
} from "@mui/material"
import {
  fetchInactiveEmpCount,
  fetchInactiveEmpRecords,
  updateLWD,
  revokeTermination,
  downloadExperienceLetter,
  downloadTerminationLetter,
  checkEmpUnderManager,
} from "../../../Actions/commonController"
import SearchBar from "../../SearchBar"
import PaginationTable from "../../Pagination"
import { toast } from "react-toastify"
import dayjs from "dayjs"
import ResponsiveDatePicker from "../../DatePicker/ResponsiveDatePicker"
import DownloadIcon from "@mui/icons-material/Download"
import { GenerateExperienceLetter } from "../../Utilities/GenerateExperienceLetter"
import { GenerateTermationLetter } from "../../Utilities/GenerateTerminationLetter"
import { useAuth } from "../../../Context/authContext"
import { useNavigate } from "react-router-dom"

// Modals
import PersonalDetailsModal from "../../Modals/PersonalDetailsModal"
import EmployeeDetailsModal from "../../Modals/EmployeeDetailsModal"
import JoiningDetailsModal from "../../Modals/JoiningDetailsModal"
import VisaDetailsModal from "../../Modals/VisaDetailsModal"
import ContactDetailsModal from "../../Modals/ContactDetailsModal"
import SalaryDetailsModal from "../../Modals/SalaryDetailsModal"
// import ContractDetailsModal from "../../Modals/ContractDetailsModal"
import DocumentDetailsModal from "../../Modals/DocumentDetailsModal"

const columns = [
  { id: "Id", label: "EMPID", minWidth: 40 },
  { id: "name", label: "NAME", minWidth: 150 },
  { id: "personalDetails", label: "PERSONAL DETAILS", minWidth: 80 },
  { id: "employeeDetails", label: "EMPLOYEE DETAILS", minWidth: 80 },
  { id: "joiningDetails", label: "JOINING DETAILS", minWidth: 80 },
  { id: "visaDetails", label: "VISA DETAILS", minWidth: 80 },
  { id: "contactDetails", label: "CONTACT DETAILS", minWidth: 80 },
  { id: "salaryDetails", label: "SALARY DETAILS", minWidth: 80 },
  // { id: "contractDetails", label: "CONTRACT DETAILS", minWidth: 80 },
  { id: "documentDetails", label: "DOCUMENT DETAILS", minWidth: 80 },
  { id: "actions", label: "ACTIONS", minWidth: 50 },
]

const actionButtonStyle = {
  margin: "4px",
  fontSize: "10px",
}

export default function InactiveEmployeeTable() {

  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [selectedData, setSelectedData] = useState(null)
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [detailsModalOpen, setDetailsModalOpen] = useState("")
  const [loader, setLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editLoading, setEditLoading] = useState(null)
  const [initialLoad, setInitialLoad] = useState(false)
  const [editLWDModalOpen, setEditLWDModalOpen] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [newLWD, setNewLWD] = useState("")
  const [revokeTerminationModal, setRevokeTerminationModal] = useState(false)
  const [selectedEmployeeForRevoke, setSelectedEmployeeForRevoke] = useState(null)
  const [loadingRevoke, setLoadingRevoke] = useState(false)
  const [downloadingExpLetterPDF, setDownloadingExpLetterPDF] = useState(null)
  const [downloadingTermLetterPDF, setDownloadingTermLetterPDF] = useState(null)
  const [downloadingExpLetterWord, setDownloadingExpLetterWord] = useState(null)
  const [downloadingTermLetterWord, setDownloadingTermLetterWord] = useState(null)
  const [expLetterAnchorEl, setExpLetterAnchorEl] = useState(null)
  const [termLetterAnchorEl, setTermLetterAnchorEl] = useState(null)
  const { logout, salaryEdit, eid } = useAuth()
  const navigation = useNavigate()

  const handleSearch = async (searchData, type) => {
    setSearchTerm(searchData)
  }

  const initialization = async () => {
    try {
      let APIData = {
        search: searchTerm,
        empid: eid
      }
      setLoader(true)
      let { status, data } = await fetchInactiveEmpCount(APIData, logout)

      if (status === "success") {
        setTotalItems(data)
        loadData()
      }
    } catch (error) {
      console.error("Error in loadData function:", error)
    }
  }

  const loadData = async () => {
    try {
      let APIData = {
        quantity: quantity,
        page: page,
        search: searchTerm,
        empid: eid
      }
      setLoader(true)
      let { status, data } = await fetchInactiveEmpRecords(APIData, logout)
      if (status === "success") {
        setData(data)
      }
      else console.error("Error fetching employee records:", status)
    } catch (err) {
      console.error("Error in loadData function:", err)
    }
    setLoader(false)
  }

  const handlePageClick = async (newPage) => {
    setPage(newPage)
  }

  const handleItemsPerPageChange = async (newRowsPerPage) => {
    setQuantity(newRowsPerPage)
    setPage(1)
  }

  const checkPermission = async (empId) => {
    try {
      let APIData = {
        empid: empId,
        linemanagerid: eid, // Current logged-in user ID
      };

      const { status, data } = await checkEmpUnderManager(APIData);
      if (status === "success" && data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during permission check:", error);
      return false;
    }
  };

  const handleButtonClick = async (columnId, empId) => {
    const employee = data.find((item) => item.empid === empId);

    if (!employee) {
      toast.error("Employee data could not be found.");
      return;
    }

    // Salary and contract details logic
    if (["salaryDetails", "contractDetails"].includes(columnId)) {
      if (salaryEdit === "notallowed") {
        toast.error("Access denied: You cannot view this information.");
        return;
      }

      if (salaryEdit === "view") {
        const hasPermission = await checkPermission(empId);
        if (!hasPermission) {
          toast.error("Access denied: You are not authorized to view this employee's details.");
          return;
        }
      }

      setSelectedData(empId);
      setDetailsModalOpen(columnId);
      return;
    }

    // Default behavior for other columns
    setSelectedData(empId);
    setDetailsModalOpen(columnId);
  };

  const handleCloseModal = () => {
    setSelectedData(null)
    setDetailsModalOpen("")
  }

  const handleEditButtonClick = async (empid) => {

    setEditLoading(empid)

    try {
      //permisson to edit all the information
      if (salaryEdit === "edit") {
        navigation(`/employee/edit-employee/${empid}`)
        return
      }

      //rest cant edit their own details
      if (empid === eid) {
        toast.error("Access denied: You cannot edit your own information.")
        setEditLoading(null)
        return
      }

      //edit if the user is under the user's line management
      if (salaryEdit === "view") {
        const hasPermission = await checkPermission(empid, eid)
        if (hasPermission) {
          navigation(`/employee/edit-employee/${empid}`)
        } else {
          toast.error("Access denied: You are not authorized to edit this employee's information.")
        }
      } else {
        navigation(`/employee/edit-employee/${empid}`)
      }
    } catch (error) {
      toast.error("An error occurred while checking permissions.")
      console.error("Error in handleEditButtonClick:", error)
    } finally {
      setEditLoading(null)
    }
  }

  const handleEditLWDClick = async (employee) => {
    if (salaryEdit === "notallowed") {
      toast.error("Access denied: You cannot edit the Last Working Day.");
      return;
    }

    if (salaryEdit === "view") {
      const hasPermission = await checkPermission(employee.empid);
      if (!hasPermission) {
        toast.error("Access denied: You are not authorized to edit this employee's Last Working Day.");
        return;
      }
    }

    setSelectedEmployee(employee);
    setNewLWD(dayjs(employee.lwd).format("YYYY-MM-DD"));
    setEditLWDModalOpen(true);
  };

  const handleUpdateLWD = async () => {
    if (!newLWD) {
      toast.error("Please select a new Last Working Day.")
      return
    }
    try {
      let APINewDate = {
        empid: selectedEmployee.empid,
        newLWD: dayjs(newLWD).format('YYYY-MM-DD'),
      }
      setLoading(true)
      const { status } = await updateLWD(APINewDate)
      if (status === "success") {
        toast.success("Last Working Day updated successfully")
        setEditLWDModalOpen(false)
        loadData()
      } else {
        toast.error("Failed to update Last Working Day")
      }
    } catch (error) {
      toast.error("An error occurred while updating Last Working Day", error)
    }
    setLoading(false)
  }

  const handleRevokeTerminationClick = async (item) => {
    if (salaryEdit === "notallowed") {
      toast.error("Access denied: You do not have permissions to revoke this termination.");
      return;
    }

    const hasPermission = await checkPermission(item.empid);
    if (!hasPermission && salaryEdit === "view") {
      toast.error("Access denied: You are not authorized to revoke this termination.");
      return;
    }

    setSelectedEmployeeForRevoke(item);
    setRevokeTerminationModal(true);
  };

  const handleCancelRevoke = () => {
    setSelectedEmployeeForRevoke(null)
    setRevokeTerminationModal(false)
  }

  const handleConfirmRevoke = async () => {
    try {
      setLoadingRevoke(true)
      let APIData = {
        empid: selectedEmployeeForRevoke.empid
      }
      let { status } = await revokeTermination(APIData)
      if (status === "success") {
        toast.success("Employee re-activated successfully!")
        setRevokeTerminationModal(false)
        loadData()
      } else {
        toast.error("Failed to revoke employee termination")
      }
    } catch (error) {
      toast.error("An error occured while revoking termination", error)
    }
    setLoadingRevoke(false)
  }

  const handleExpLetterDownload = async (empid, gender, sponsorname, format) => {

    if (salaryEdit === "notallowed") {
      toast.error("Access denied: You cannot download letters.");
      return;
    }

    const hasPermission = await checkPermission(empid);
    if (!hasPermission && salaryEdit === "view") {
      toast.error("Access denied: You are not authorized to download this employee's letters.");
      return;
    }

    try {
      if (format === 'pdf') {
        setDownloadingExpLetterPDF(empid)
      } else if (format === 'word') {
        toast.error("This feature is not available right now!")
        return
      }
      const { status, data } = await downloadExperienceLetter({ empid })

      if (status === "success") {
        const { dateofjoin, lwd, sponsorname, gender, ...restData } = data

        const dateofjoinFormatted = new Date(dateofjoin).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit'
        })

        const lwdFormatted = lwd ? new Date(lwd).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit'
        }) : "N/A"

        const today = formatDateWithSuffix(new Date())
        const title = gender === "Female" ? "Ms." : "Mr."
        const pronoun1 = gender === "Female" ? "her" : "his"
        const pronoun2 = gender === "Female" ? "her" : "him"

        const employee = {
          ...restData,
          dateofjoin: dateofjoinFormatted,
          lwd: lwdFormatted,
          sponsorname: sponsorname,
        }

        GenerateExperienceLetter(employee, today, title, pronoun1, pronoun2, sponsorname, format)

        toast.success("Experience Letter downloaded successfully!")
      } else {
        toast.error("Failed to download experience letter.")
      }
    } catch (error) {
      console.error("Error downloading experience letter:", error)
    } finally {
      if (format === 'pdf') {
        setDownloadingExpLetterPDF(null)
      } else if (format === 'word') {
        setDownloadingExpLetterWord(null)
      }
    }
  }

  const handleTerminationLetterDownload = async (empid, gender, sponsorname, format) => {
    if (salaryEdit === "notallowed") {
      toast.error("Access denied: You cannot download termination letters.");
      return;
    }

    const hasPermission = await checkPermission(empid);
    if (!hasPermission && salaryEdit === "view") {
      toast.error("Access denied: You are not authorized to download this employee's letters.");
      return;
    }
    try {
      if (format === 'pdf') {
        setDownloadingTermLetterPDF(empid)
      } else if (format === 'word') {
        toast.error("This feature is not available right now!")
        return
      }
      const { status, data } = await downloadTerminationLetter({ empid })

      if (status === "success") {
        const { lwd, sponsorname, ...restData } = data

        const lwdFormatted = lwd ? new Date(lwd).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit'
        }) : "N/A"

        const today = formatDateWithSuffix(new Date())
        const title = gender === "Female" ? "Ms." : "Mr."

        const employee = {
          ...restData,
          lwd: lwdFormatted,
          sponsorname: sponsorname,
        }

        GenerateTermationLetter(employee, today, title, sponsorname, format)

        toast.success("Termination Letter downloaded successfully!")
      } else {
        toast.error("Failed to download termination letter.")
      }
    } catch (error) {
      console.error("Error downloading termination letter:", error)
    } finally {
      if (format === 'pdf') {
        setDownloadingTermLetterPDF(null)
      } else if (format === 'word') {
        setDownloadingTermLetterWord(null)
      }
    }
  }

  const formatDateWithSuffix = (date) => {
    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear()

    const suffix = (d) => {
      if (d > 3 && d < 21) return "th"
      switch (d % 10) {
        case 1: return "st"
        case 2: return "nd"
        case 3: return "rd"
        default: return "th"
      }
    }

    return `${day}${suffix(day)} ${month}, ${year}`
  }

  const handleExpLetterButtonClick = (event, employee) => {
    setExpLetterAnchorEl(event.currentTarget)
    setSelectedEmployee(employee)
  }

  const handleTermLetterButtonClick = (event, employee) => {
    setTermLetterAnchorEl(event.currentTarget)
    setSelectedEmployee(employee)
  }

  const handlePopoverClose = () => {
    setExpLetterAnchorEl(null)
    setTermLetterAnchorEl(null)
  }

  useEffect(() => {
    if (initialLoad) loadData()
    setInitialLoad(true)
  }, [page, quantity])

  useEffect(() => {
    initialization()
  }, [searchTerm])

  return (
    <Paper className="pagePaper">
      <span className="info">
        <h5>Inactive Employee Information</h5>
        <SearchBar searchText={"Search by name..."} onSearch={handleSearch} searchType={"name"} />
        <SearchBar searchText={"Search by empid..."} onSearch={handleSearch} searchType={"empid"} />
      </span>

      <div className="row" style={{ paddingTop: "60px" }}>
        <div className="col-md-12">
          <TableContainer sx={{ overflowY: "auto", maxHeight: "500px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className="tableHeader"
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loader ? (
                <TableRow>
                  <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                    <CircularProgress size={100} />
                  </TableCell>
                </TableRow>
              ) : data && data.length > 0 ? (
                data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" className="p-0">{item.empid}</TableCell>
                    <TableCell align="center" className="p-0">{item.name}</TableCell>
                    {columns.slice(2, columns.length).map((column) => (
                      <TableCell className="p-0" key={column.id} align="center">
                        {column.id === "actions" ? (
                          <div>
                            <Button
                              variant="contained"
                              onClick={() => handleEditButtonClick(item.empid)}
                              style={{
                                ...actionButtonStyle,
                                backgroundColor: "orange",
                                fontSize: "10px",
                              }}
                              disabled={editLoading === item.empid}
                            >
                              {editLoading === item.empid ? <CircularProgress size={18} sx={{ color: "white" }} /> : "Edit"}
                            </Button>
                            <Button variant="contained" className="btn success" onClick={() => handleRevokeTerminationClick(item)}>
                              Re-Activate
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => handleEditLWDClick(item)}
                              style={{
                                ...actionButtonStyle,
                                backgroundColor: "brown",
                                fontSize: "10px",
                              }}
                            >
                              Edit LWD
                            </Button>
                            <>
                              <Button
                                variant="contained"
                                className="btn expLetter"
                                onClick={(event) => handleExpLetterButtonClick(event, item)}
                              >
                                {downloadingExpLetterPDF === item.empid || downloadingExpLetterWord === item.empid ? (
                                  <CircularProgress size={16} sx={{ color: "white" }} />
                                ) : (
                                  <>
                                    <DownloadIcon style={{ fontSize: "20px" }} />
                                    Experience Letter
                                  </>
                                )}
                              </Button>
                              <Popover
                                open={Boolean(expLetterAnchorEl)}
                                anchorEl={expLetterAnchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <Stack>
                                  <Button
                                    onClick={() => handleExpLetterDownload(selectedEmployee.empid, selectedEmployee.gender, selectedEmployee.sponsorname, 'pdf')}
                                    sx={{ justifyContent: 'flex-start', padding: '10px 20px' }}
                                  >
                                    Download as PDF
                                  </Button>
                                  <Button
                                    onClick={() => handleExpLetterDownload(selectedEmployee.empid, selectedEmployee.gender, selectedEmployee.sponsorname, 'word')}
                                    sx={{ justifyContent: 'flex-start', padding: '10px 20px' }}
                                  >
                                    Download as Word
                                  </Button>
                                </Stack>
                              </Popover>
                            </>
                            <>
                              <Button
                                variant="contained"
                                className="btn terminationLetter"
                                onClick={(event) => handleTermLetterButtonClick(event, item)}
                              >
                                {downloadingTermLetterPDF === item.empid || downloadingTermLetterWord === item.empid ? (
                                  <CircularProgress size={16} sx={{ color: "white" }} />
                                ) : (
                                  <>
                                    <DownloadIcon style={{ fontSize: "20px" }} />
                                    Termination Letter
                                  </>
                                )}
                              </Button>
                              <Popover
                                open={Boolean(termLetterAnchorEl)}
                                anchorEl={termLetterAnchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <Stack>
                                  <Button
                                    onClick={() => handleTerminationLetterDownload(selectedEmployee.empid, selectedEmployee.gender, selectedEmployee.sponsorname, 'pdf')}
                                    sx={{ justifyContent: 'flex-start', padding: '10px 20px' }}
                                  >
                                    Download as PDF
                                  </Button>
                                  <Button
                                    onClick={() => handleTerminationLetterDownload(selectedEmployee.empid, selectedEmployee.gender, selectedEmployee.sponsorname, 'word')}
                                    sx={{ justifyContent: 'flex-start', padding: '10px 20px' }}
                                  >
                                    Download as Word
                                  </Button>
                                </Stack>
                              </Popover>
                            </>
                          </div>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() => handleButtonClick(column.id, item.empid)}
                            style={{
                              backgroundColor: "#2596BE",
                              fontSize: "10px",
                            }}
                          >
                            View
                          </Button>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                    No results found for "{searchTerm}"
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>

          <PaginationTable
            totalItems={totalItems}
            itemsPerPage={quantity}
            onPageChange={handlePageClick}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </div>
      </div>

      {/* Edit Last Working Day Modal */}
      <Modal
        open={editLWDModalOpen}
        onClose={() => setEditLWDModalOpen(false)}
        aria-labelledby="edit-lwd-modal-title"
        aria-describedby="edit-lwd-modal-description"
      >
        <Paper className="editLWD">
          <Typography id="edit-lwd-modal-title" variant="h6" component="h2" sx={{ paddingBottom: "10px" }}>
            Change Last Working Day
          </Typography>
          <ResponsiveDatePicker
            autoFocus
            margin="dense"
            id="newLWD"
            type="date"
            fullWidth
            value={newLWD}
            handleDate={(e) => setNewLWD(dayjs(e.target.value).format('DD/MM/YYYY'))}
            sx={{ flex: 1 }}
            InputProps={{
              inputProps: {
                min: dayjs().format('DD/MM/YYYY'),
              },
            }}
          />
          <div style={{ display: "flex", marginTop: "25px", justifyContent: "space-between", fontSize: "12px" }}>
            <Button
              variant="contained"
              onClick={() => setEditLWDModalOpen(false)}
              style={{ backgroundColor: "#FF0000", color: "white", width: "80px", height: "35px" }}
            >
              Cancel
            </Button>
            <Button
              sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, width: "80px", height: "35px" }}
              onClick={handleUpdateLWD}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Save"}
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Revoke Termination Modal */}
      <Modal open={revokeTerminationModal} onClose={handleCancelRevoke} aria-labelledby="revoke-modal-title" aria-describedby="revoke-modal-description">
        <Paper className="floatingPaper">
          <Typography id="revoke-modal-title" variant="h6" component="h2">
            Re-Activate Employee
          </Typography>
          <Typography id="revoke-modal-description" sx={{ mt: 2, color: "gray" }}>
            Are you sure you want to re-activate this employee?
          </Typography>
          <div style={{ display: "flex", marginTop: "20px", width: "370px", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancelRevoke}
              style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}>
              No
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                width: "60px",
                height: "35px",
              }}
              autoFocus
              onClick={handleConfirmRevoke}
              disabled={loadingRevoke}
            >
              {loadingRevoke ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Yes"}
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Rendering Modals with their selected row data */}
      <PersonalDetailsModal
        open={detailsModalOpen === "personalDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      />

      <EmployeeDetailsModal
        open={detailsModalOpen === "employeeDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      />

      <JoiningDetailsModal
        open={detailsModalOpen === "joiningDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      />

      <VisaDetailsModal
        open={detailsModalOpen === "visaDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      />

      <ContactDetailsModal
        open={detailsModalOpen === "contactDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      />

      <SalaryDetailsModal
        open={detailsModalOpen === "salaryDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      />

      {/* <ContractDetailsModal
        open={detailsModalOpen === "contractDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      /> */}

      <DocumentDetailsModal
        open={detailsModalOpen === "documentDetails"}
        onClose={handleCloseModal}
        empId={selectedData}
      />

    </Paper>
  )
}