import React, { useState, useEffect } from "react"
import { fetchSingleEmpRecord } from "../../Actions/commonController"
import { useParams, Link } from "react-router-dom"
import { styled } from "@mui/material/styles"
import { useAuth } from "../../Context/authContext"
import {
  Grid,
  Paper,
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  InputAdornment,
} from "@mui/material"
import { FormatDate } from "../Utilities/DateFormatter"

const initialValue = {
  name: "",
  email: "",
  gender: "",
  nationality: "",
  dob: "",
  age: "",
  passportno: "",
  passportexpiry: "",
  passportissuingcountry: "",
  passportissuestate: "",
  // passportstatus: "",
  contactuae: "",
  maritalstatus: "",
  religion: "",
  // photoloc: [],
  eidno: "",
  gpssa: "",
  insuredno: "",
  insureddate: "",
  dateofjoin: "",
  designation: "",
  officeloc: "",
  bankname: "",
  dept: "",
  division: "",
  // hiredfrom: "",
  sourcecode: "",
  linemanager: "",
  teamleader: "",
  visaid: "",
  dateofjoinmol: "",
  workpermitnumber: "",
  workpermitstartdate: "",
  workpermitexpiry: "",
  personalcodemohre: "",
  contracttype: "",
  sponsorname: "",
  labourcard: "",
  visafileno: "",
  visadesignation: "",
  // visatype: "",
  visaexpiry: "",
  // visacancellation: "",
  contacthome: "",
  emergencycontactuaename: "",
  emergencycontactuaeno: "",
  emergencycontacthomename: "",
  emergencycontacthomeno: "",
  companysimnumber: "",
  simissuedate: "",
  simlastdate: "",
  simplan: "",
  medicalprovider: "",
  medicalexpiry: "",
  medicalcardno: "",
  basicsalary: "",
  housingallowance: "",
  transportallowance: "",
  foodallowance: "",
  mobileallowance: "",
  otherallowance: "",
  totalsalary: "",
  contractsalary: "",
  accnumber: "",
  iban: "",
  salarybankname: "",
  folderid: "",
  // remarks: "",
  // contractupdate: [],
  // docslist: [],
  // docstatus: "",
  // previousempemail: "",
  // verificationstatus: "",
  // emailsenton: "",
  // repliedon: "",
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "5px",
  fontFamily: "Open Sans",
  boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
}))

const RowContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing("20px"),
}))

const TableWrapper = styled(TableContainer)(({ theme }) => ({
  overflowY: "scroll",
  margin: "0",
}))

export default function UserProfileForm() {

  const [formValue, setFormValue] = useState(initialValue)
  const [uploadedDocs, setUploadedDocs] = useState([])
  const [uploadedImage, setUploadedImage] = useState("")
  const { eid } = useAuth()
  const { empid } = useParams()

  const {
    name, email, gender, nationality, dob, age, passportno, passportexpiry, passportissuingcountry,
    passportissuestate, contactuae, maritalstatus, religion, dateofjoin, designation, officeloc,
    bankname, dept, division, sourcecode, linemanager, teamleader, visaid, dateofjoinmol, workpermitnumber,
    workpermitstartdate, workpermitexpiry, personalcodemohre, contracttype, sponsorname, labourcard,
    visafileno, visadesignation, visaexpiry, eidno, gpssa, insuredno,
    insureddate, contacthome, emergencycontactuaename, emergencycontactuaeno, emergencycontacthomename,
    emergencycontacthomeno, companysimnumber, simissuedate, simlastdate, simplan, medicalprovider,
    medicalexpiry, medicalcardno, basicsalary, housingallowance, transportallowance, foodallowance,
    mobileallowance, otherallowance, totalsalary, contractsalary, accnumber, iban, salarybankname, folderid
  } = formValue || {}

  const fetchData = async () => {
    try {
      let APIData = {
        empid: eid,
      }
      let { data, status, docs } = await fetchSingleEmpRecord(APIData)
      if (status === "success") {
        setFormValue(data)
        setUploadedDocs(docs)
        // setUploadedImage(data.photoloc)
      } else {
        console.error("Error fetching employee data:", data)
        // console.error("Error fetching employee image:", photoloc)
        console.error("Error fetching employee docs:", docs)
      }
    } catch (error) {
      console.error("Error fetching employee data:", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [eid])


  return (

    <Box>

      {/* row 1 */}
      <RowContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Item sx={{ padding: "30px", fontFamily: "Open Sans" }}>
              <h3 className="cardTitle">PERSONAL INFORMATION</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5} lg={5} xl={4}>
                  <div className="label">Full Name</div>
                  <TextField variant="standard" name="name" value={name} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <div className="label">E-mail</div>
                  <TextField variant="standard" name="email" value={email} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={8} sm={5} md={3} lg={2} xl={2}>
                  <div className="label">Contact No.</div>
                  <TextField variant="standard" name="contactuae" value={contactuae} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={5} md={3} lg={3} xl={2}>
                  <div className="label">Date of Birth</div>
                  <TextField variant="standard" name="dob" value={FormatDate(dob)} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={4} sm={2} md={1} lg={1} xl={1}>
                  <div className="label">Age</div>
                  <TextField variant="standard" name="age" value={age} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={7} md={4} lg={3} xl={2}>
                  <div className="label">Nationality</div>
                  <TextField variant="standard" name="nationality" value={nationality} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={5} md={2} lg={2} xl={2}>
                  <div className="label">Gender</div>
                  <TextField variant="standard" value={gender} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <div className="label">Marital Status</div>
                  <TextField variant="standard" value={maritalstatus} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={5} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Religion</div>
                  <TextField variant="standard" name="religion" value={religion} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                {/* <Grid item xs={24}>
                  <div className="label">Photo</div>
                </Grid>
                <Grid item xs={24}>
                  <Link to={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${uploadedImage}`} target="_blank">
                    {uploadedImage?.length > 0 && folderid &&
                      <Avatar
                        alt="Profile Photo"
                        src={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${uploadedImage}`}
                        sx={{ width: 100, height: 100, borderRadius: 0 }}
                      />
                    }
                  </Link>
                </Grid> */}
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 2 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item sx={{ padding: "30px", fontFamily: "Open Sans" }}>
              <h3 className="cardTitle">PASSPORT & VISA INFORMATION</h3>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
                  <div className="label">Passport No.</div>
                  <TextField variant="standard" name="passportno" value={passportno} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
                  <div className="label">Passport Issuing Country</div>
                  <TextField variant="standard" name="passportissuingcountry" value={passportissuingcountry} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={2}>
                  <div className="label">Passport Issuing State</div>
                  <TextField variant="standard" name="passportissuestate" value={passportissuestate} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
                  <div className="label">Passport Expiry</div>
                  <TextField variant="standard" name="passportexpiry" value={FormatDate(passportexpiry)} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={3} xl={2}>
                  <div className="label">Sponsor Name</div>
                  <TextField variant="standard" name="sponsorname" value={sponsorname} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">Labour Card Under</div>
                  <TextField variant="standard" name="labourcard" value={labourcard} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Contract Type</div>
                  <TextField variant="standard" name="contracttype" value={contracttype} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Visa File No.</div>
                  <TextField variant="standard" name="visafileno" value={visafileno} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">UID</div>
                  <TextField variant="standard" name="visaid" value={visaid} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Visa Designation</div>
                  <TextField variant="standard" name="visadesignation" value={visadesignation} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Visa Expiry</div>
                  <TextField variant="standard" name="visaexpiry" value={FormatDate(visaexpiry)} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Emirates ID No.</div>
                  <TextField variant="standard" name="eidno" value={eidno} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={7} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Work Permit No.</div>
                  <TextField variant="standard" name="workpermitnumber" value={workpermitnumber} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={7} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Work Permit Expiry</div>
                  <TextField variant="standard" name="workpermitexpiry" value={FormatDate(workpermitexpiry)} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={7} sm={6} md={4} lg={7} xl={3}>
                  <div className="label">Personal Code (MOHRE)</div>
                  <TextField variant="standard" name="personalcodemohre" value={personalcodemohre} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <div className="label">Medical Insurance Provider</div>
                  <TextField variant="standard" name="medicalprovider" value={medicalprovider} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <div className="label">Medical Insurance Card No.</div>
                  <TextField variant="standard" name="medicalcardno" value={medicalcardno} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                  <div className="label">Medical Insurance Expiry</div>
                  <TextField variant="standard" name="medicalexpiry" value={FormatDate(medicalexpiry)} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                {/* Additional fields */}
                {formValue.nationality === "U.A.E" && (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div className="label">GPSSA</div>
                      <TextField variant="standard" name="gpssa" value={gpssa} fullWidth InputProps={{ readOnly: true }} disabled />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div className="label">Insured No.</div>
                      <TextField variant="standard" name="insuredno" value={insuredno} fullWidth InputProps={{ readOnly: true }} disabled />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <div className="label">Insured Date</div>
                      <TextField variant="standard" name="insureddate" value={insureddate} fullWidth InputProps={{ readOnly: true }} disabled />
                    </Grid>
                  </>
                )}
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 3 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item sx={{ padding: "30px", fontFamily: "Open Sans" }}>
              <h3 className="cardTitle">EMPLOYMENT DETAILS</h3>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                  <div className="label">Date of Join (Actual)</div>
                  <TextField variant="standard" name="dateofjoin" value={FormatDate(dateofjoin)} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">MOL Start Date</div>
                  <TextField variant="standard" name="dateofjoinmol" value={FormatDate(dateofjoinmol)} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={2}>
                  <div className="label">Office Location</div>
                  <TextField variant="standard" name="officeloc" value={officeloc} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                  <div className="label">Designation</div>
                  <TextField variant="standard" name="designation" value={designation} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <div className="label">Bank Name</div>
                  <TextField variant="standard" name="bankname" value={bankname} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={4} xl={2}>
                  <div className="label">Department</div>
                  <TextField variant="standard" value={dept} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={5} lg={4} xl={2}>
                  <div className="label">Division</div>
                  <TextField variant="standard" value={division} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className="label">Line Manager</div>
                  <TextField variant="standard" value={linemanager} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <div className="label">Team Leader</div>
                  <TextField variant="standard" value={teamleader} fullWidth InputProps={{ readOnly: true }} disabled>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                  <div className="label">Source Code</div>
                  <TextField variant="standard" name="sourcecode" value={sourcecode} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 4 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item sx={{ padding: "30px", fontFamily: "Open Sans" }}>
              <h3 className="cardTitle">CONTACT DETAILS</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact Name (UAE)</div>
                  <TextField variant="standard" name="emergencycontactuaename" value={emergencycontactuaename} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact No. (UAE)</div>
                  <TextField variant="standard" name="emergencycontactuaeno" value={emergencycontactuaeno} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <div className="label">Contact No. (Home Country)</div>
                  <TextField variant="standard" name="contacthome" value={contacthome} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact Name (Home Country)</div>
                  <TextField variant="standard" name="emergencycontacthomename" value={emergencycontacthomename} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <div className="label">Emergency Contact No. (Home Country)</div>
                  <TextField variant="standard" name="emergencycontacthomeno" value={emergencycontacthomeno} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 5 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item sx={{ padding: "30px", fontFamily: "Open Sans" }}>
              <h3 className="cardTitle">SALARY DETAILS</h3>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Basic Salary</div>
                  <TextField variant="standard" name="basicsalary" value={basicsalary} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Housing Allowance</div>
                  <TextField variant="standard" name="housingallowance" value={housingallowance} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Transport Allowance</div>
                  <TextField variant="standard" name="transportallowance" value={transportallowance} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Food Allowance</div>
                  <TextField variant="standard" name="foodallowance" value={foodallowance} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Mobile Allowance</div>
                  <TextField variant="standard" name="mobileallowance" value={mobileallowance} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Other Allowance</div>
                  <TextField variant="standard" name="otherallowance" value={otherallowance} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Total Salary</div>
                  <TextField variant="standard" name="totalsalary" value={totalsalary} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                  <div className="label">Actual Salary</div>
                  <TextField variant="standard" name="contractsalary" value={contractsalary} fullWidth InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span>/- AED</span>
                      </InputAdornment>
                    ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={3} lg={3} xl={3}>
                  <div className="label">Account No.</div>
                  <TextField variant="standard" name="accnumber" value={accnumber} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                  <div className="label">IBAN</div>
                  <TextField variant="standard" name="iban" value={iban} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                  <div className="label">Bank No.</div>
                  <TextField variant="standard" name="salarybankname" value={salarybankname} fullWidth InputProps={{ readOnly: true }} disabled />
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 6 */}
      <RowContainer>
        <Item sx={{
          height: "300px",
          padding: "30px 20px 20px 20px",
          fontFamily: "Open Sans",
          overflow: "hidden",
        }}>
          <Grid item xs={12}>
            <h3 className="cardTitle" style={{ margin: "0", padding: "0" }}>DOCUMENTS</h3>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{
                      margin: "0"
                    }}>
                      File Name
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ maxHeight: "200px", overflowY: "scroll" }}>
                  {uploadedDocs && uploadedDocs?.length > 0 && uploadedDocs.map((doc, index) => (
                    <TableRow key={index}>
                      <TableCell style={{
                        margin: "0"
                      }}>
                        <Link to={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${doc.filename}`} target="_blank" style={{ color: "grey", textDecoration: "none" }}>
                          {doc.filename}
                        </Link>
                      </TableCell>
                      <TableCell style={{
                        margin: "0",
                        textAlign: "right"
                      }}>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          </Grid>
        </Item>
      </RowContainer>

    </Box >

  )
}

