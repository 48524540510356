import React from "react"
import AllEmployeeTable from "../../Components/Tables/EmployeeTables/AllEmployeeTable"

function AllEmployee ( ) {
  return (
    <AllEmployeeTable/>
  )
}

export default AllEmployee
