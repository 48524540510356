import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import CloseIcon from "@mui/icons-material/Close"
import { Modal, CircularProgress, Divider, Avatar } from "@mui/material"
import { fetchEmpPersonalDetails } from "../../Actions/commonController"
import moment from "moment"

function parseDate(dateStr) {
  const parts = dateStr.split("/")
  if (parts.length !== 3) return null
  const [day, month, year] = parts.map((part) => parseInt(part, 10))
  if (!day || !month || !year) return null
  return new Date(year, month - 1, day)
}

function calculateAge(dob) {
  const today = new Date()
  const birthDate = new Date(dob)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export default function PersonalDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [personalDetails, setPersonalDetails] = useState(null)

  const fetchData = async () => {
    try {
      setLoader(true)
      let APIData = {
        empid: empId,
      }
      if (empId) {
        let { data, status } = await fetchEmpPersonalDetails(APIData)
        if (status === "success") {
          const details = data[0]
          if (details.dob) {
            const parsedDob = new Date(details.dob)
            if (parsedDob) {
              details.age = calculateAge(parsedDob)
            }
          }
          setPersonalDetails(details)
        }
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching personal details data:", error)
    }
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId, open])

  const formatDate = (date) => {
    return moment(date).format("Do MMMM, YYYY")
  }

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Personal Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          <p id="keep-mounted-modal-description" className="field">
            Gender
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.gender ? personalDetails.gender : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Nationality
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.nationality ? personalDetails.nationality : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Date of Birth
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.dob ? formatDate(personalDetails.dob) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Age
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.age ? personalDetails.age : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Passport No.
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.passportno ? personalDetails.passportno : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Passport Issuing Country
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.passportissuingcountry ? personalDetails.passportissuingcountry : "-"
            )}
          </h4>


          <p id="keep-mounted-modal-description" className="field">
            Passport Issue State
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.passportissuestate ? personalDetails.passportissuestate : "-"
            )}
          </h4>


          <p id="keep-mounted-modal-description" className="field">
            Passport Expiry
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.passportexpiry ? formatDate(personalDetails.passportexpiry) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Email Address
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.email ? personalDetails.email : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Contact Number (UAE)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.contactuae ? personalDetails.contactuae : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Marital Status
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.maritalstatus ? personalDetails.maritalstatus : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Religion
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.religion ? personalDetails.religion : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Remarks
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              personalDetails?.remarks ? personalDetails.remarks : "-"
            )}
          </h4>

          {/* <p id="keep-mounted-modal-description" className="field">
            Photo
          </p>
          {personalDetails?.photoloc && (
            <Avatar
              alt="Profile Photo"
              src={`https://fmapi.fintrekmarketing.com:2083/uploads/${personalDetails.folderid}/${personalDetails.photoloc}`}
              sx={{ width: 100, height: 100, borderRadius: 0 }}
            />
          )} */}

          <div className="division">
            <h3 id="keep-mounted-modal-description" className="centered-heading">
              Background Check
            </h3>

            <p id="keep-mounted-modal-description" className="field">
              Previous Employer's Email
            </p>

            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : personalDetails?.previousempemail ? (
                personalDetails.previousempemail
              ) : (
                "-"
              )}
            </h4>

            <p id="keep-mounted-modal-description" className="field">
              Verification Status
            </p>
            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : personalDetails?.verificationstatus ? (
                personalDetails.verificationstatus
              ) : (
                "-"
              )}
            </h4>

            <p id="keep-mounted-modal-description" className="field">
              Email Sent Date
            </p>
            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : personalDetails?.emailsenton ? (
                formatDate(personalDetails.emailsenton)
              ) : (
                "-"
              )}
            </h4>

            <p id="keep-mounted-modal-description" className="field">
              Email Received Date
            </p>
            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : personalDetails?.repliedon ? (
                formatDate(personalDetails.repliedon)
              ) : (
                "-"
              )}
            </h4>
          </div>

        </div>
      </div>
    </Modal>
  )
}

const Backdrop = (props) => {
  const { open, className, ...other } = props;

  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      {...other}
    />
  );
};

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};