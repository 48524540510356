import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import sidebarData from "../json/sidebarDataAdmin.json"
import sidebarDataEmp from "../json/sidebarDataEmployees.json"
import sidebarDataMain from "../json/sidebarDataAdminMain.json"
import {
  Dashboard,
  Work,
  AccountBalanceWallet,
  Description,
  AccountCircle,
} from "@mui/icons-material"
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import PaidIcon from "@mui/icons-material/Paid"
import LockIcon from "@mui/icons-material/Lock"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import BadgeIcon from "@mui/icons-material/Badge"
import whitelogo from "../assets/whitelogo.png"
import { useAuth } from "../Context/authContext"

const Icon = ({ iconName }) => {
  switch (iconName) {
    case "Dashboard":
      return <Dashboard className="customicon" />
    case "SupervisedUserCircleIcon":
      return <SupervisedUserCircleIcon className="customicon" />
      case "PaidIcon":
        return <PaidIcon className="customicon" />
    case "AssignmentTurnedInIcon":
      return <AssignmentTurnedInIcon className="customicon" />
    case "Leave":
      return <BadgeIcon className="customicon" />
    case "Work":
      return <Work className="customicon" />
    case "AccountBalanceWallet":
      return <AccountBalanceWallet className="customicon" />
    case "Description":
      return <Description className="customicon" />
    case "AccountCircle":
      return <AccountCircle className="customicon" />
    case "LockIcon":
      return <LockIcon className="customicon" />
    case "ExitToAppIcon":
      return <ExitToAppIcon className="customicon" />
    default:
      return null
  }
}

const Sidebar = () => {
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState([])
  const [linkActive, setLinkActive] = useState("")
  const [data, setData] = useState({})

  const { tier, logout } = useAuth()

  const handleAccordion = (id) => {
    const checkIndex = isActive.indexOf(id)
    setIsActive((prevItems) =>
      checkIndex === -1 ? [...prevItems, id] : prevItems.filter((i) => i !== id)
    )
  }

  const handleLogout = async () => {
    await logout()
    navigate("/login")
  }

  useEffect(() => {
    if (tier === 1) setData(sidebarDataMain)
    else if (tier === 2 || tier === 3) setData(sidebarData)
    else setData(sidebarDataEmp)
  }, [tier])

  return (
    <div className="sidebar">
      <div className="logo">
        <img src={whitelogo} alt="whitelogo" />
      </div>
      <div className="scrollable-content">
        {data && Object.entries(data).length > 0 && data.sections.map((section, index) => (
          <div key={index} className="section">
            <p>{section.title}</p>
            <ul>
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <Icon iconName={link.iconName} />
                  {link.links && link.links.length > 0 ? (
                    <span onClick={() => handleAccordion(linkIndex)} className="clickable">{link.label}</span>
                  ) : link.label === "Logout" ? (
                    <span onClick={handleLogout} className="clickable">{link.label}</span>
                  ) : (
                    <Link to={link.path} className="clickable">{link.label}</Link>
                  )}

                  <div
                    className={`accordian ${isActive.indexOf(linkIndex) !== -1 ? "active" : ""}`}
                  >
                    {link.links &&
                      link.links.length > 0 &&
                      link.links.map((item, itemIndex) => (
                        <Link className={`clickable ${item.path === linkActive ? "linkActive" : ""}`} key={itemIndex} to={item.path} onClick={() => { setLinkActive(item.path) }}>
                          <div>{item.label}</div>
                        </Link>
                      ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sidebar