import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Modal, CircularProgress, Divider } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { fetchEmpContactDetails } from "../../Actions/commonController"
import moment from "moment"

export default function ContactDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [contactDetails, setContactDetails] = useState(null)

  const fetchData = async () => {
    try {
      let APIData = {
        empid: empId,
      }
      if (empId) {
        setLoader(true)
        let { data, status } = await fetchEmpContactDetails(APIData)
        if (status === "success") setContactDetails(data[0])
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching personal details data:", error)
    }
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId])

  const formatDate = (date) => {
    return moment(date).format("Do MMMM, YYYY")
  }

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Contact Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          <p id="keep-mounted-modal-description" className="field">
            Contact Number (Home Country)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : contactDetails?.contacthome ? (
              contactDetails?.contacthome
            ) : (
              "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Emergency Contact Name (UAE)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : contactDetails?.emergencycontactuaename ? (
              contactDetails.emergencycontactuaename
            ) : (
              "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Emergency Contact Number (UAE)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : contactDetails?.emergencycontactuaeno ? (
              contactDetails.emergencycontactuaeno
            ) : (
              "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Emergency Contact Name (Home Country)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : contactDetails?.emergencycontacthomename ? (
              contactDetails.emergencycontacthomename
            ) : (
              "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Emergency Contact Number (Home country)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : contactDetails?.emergencycontacthomeno ? (
              contactDetails.emergencycontacthomeno
            ) : (
              "-"
            )}
          </h4>

          <div className="division">
            <h3 id="keep-mounted-modal-description" className="centered-heading">
              SIM Details
            </h3>

            <p id="keep-mounted-modal-description" className="field">
              Company SIM Number
            </p>

            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : contactDetails?.companysimnumber ? (
                contactDetails.companysimnumber
              ) : (
                "-"
              )}
            </h4>

            <p id="keep-mounted-modal-description" className="field">
              SIM Issue Date
            </p>
            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : contactDetails?.simissuedate ? (
                formatDate(contactDetails.simissuedate)
              ) : (
                "-"
              )}
            </h4>

            <p id="keep-mounted-modal-description" className="field">
              SIM Last Date
            </p>
            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : contactDetails?.simlastdate ? (
                formatDate(contactDetails.simlastdate)
              ) : (
                "-"
              )}
            </h4>

            <p id="keep-mounted-modal-description" className="field">
              SIM Plan
            </p>
            <h4>
              {loader ? (
                <CircularProgress size={24} style={{ color: "black" }} />
              ) : contactDetails?.simplan ? (
                contactDetails.simplan
              ) : (
                "-"
              )}
            </h4>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const Backdrop = (props) => {
  const { open, className, ...other } = props;

  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      {...other}
    />
  );
};

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};