import React, { useState, useEffect } from "react"
import {
    MenuItem,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
} from "@mui/material"
import { toast } from "react-toastify"
import {
    fetchDropdownOptions,
    addDropdownOption,
} from "../../Actions/commonController"

const Dropdown = ({ fieldName, dropdownOptions, setDropdownOptions, value, onChange, tier, bankDeptMapping, bankname }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [newOption, setNewOption] = useState("")
    const [loader, setLoader] = useState(false)
    const [filteredDeptOptions, setFilteredDeptOptions] = useState([])

    const handleSelection = (e) => {
        const selectedValue = e.target.value

        if (fieldName === "bankname") {
            onChange("dept", "")

            const mappedDepts = bankDeptMapping?.[selectedValue] || []
            setFilteredDeptOptions(mappedDepts)
        }

        if (selectedValue === "addNew") {
            setIsDialogOpen(true)
        } else {
            onChange(fieldName, selectedValue)
        }
    }

    const handleDialogClose = () => {
        setIsDialogOpen(false)
        setNewOption("")
    }

    const handleAddOption = async () => {
        if (!newOption.trim()) {
            toast.error("Option cannot be empty")
            return
        }

        setLoader(true)

        try {
            if (fieldName === "bankname") {
                // Add the new bank name
                const addBankResponse = await addDropdownOption({ fieldname: fieldName, newOption })

                if (addBankResponse.status === "success") {
                    toast.error("Cannot add new bankname (Contact Administrator).")

                    // Fetch updated dropdown options
                    const fetchResponse = await fetchDropdownOptions()
                    if (fetchResponse.status === "success") {
                        const updatedOptions = fetchResponse.data

                        setDropdownOptions((prev) => ({
                            ...prev,
                            bankname: updatedOptions.bankname,
                            bankDeptMapping: updatedOptions.bankDeptMapping,
                        }))

                        onChange(fieldName, newOption)
                    } else {
                        toast.error("Failed to fetch updated options.")
                    }
                } else {
                    toast.error(addBankResponse.message || "Failed to add the new bank.")
                }
            } else if (fieldName === "dept") {
                // Check if a bank name is selected
                if (!bankname) {
                    toast.error("Please select a bank name before adding departments.")
                    return
                }

                const selectedBank = bankname
                const deptFieldName = `bankname:${selectedBank}`

                // Adding the department to the selected bank
                const addDeptResponse = await addDropdownOption({
                    fieldname: deptFieldName,
                    newOption,
                })

                if (addDeptResponse.status === "success") {
                    toast.success(`Department added successfully to ${selectedBank}!`)

                    const fetchResponse = await fetchDropdownOptions()
                    if (fetchResponse.status === "success") {
                        const updatedOptions = fetchResponse.data

                        setDropdownOptions((prev) => ({
                            ...prev,
                            bankDeptMapping: updatedOptions.bankDeptMapping,
                        }))

                        onChange(fieldName, newOption)
                    } else {
                        toast.error("Failed to fetch updated options.")
                    }
                } else {
                    toast.error(addDeptResponse.message || "Failed to add department.")
                }
            } else {
                const addResponse = await addDropdownOption({ fieldname: fieldName, newOption })

                if (addResponse.status === "success") {
                    toast.success("New option added successfully!")

                    const fetchResponse = await fetchDropdownOptions()
                    if (fetchResponse.status === "success") {
                        const updatedOptions = fetchResponse.data

                        setDropdownOptions((prev) => ({
                            ...prev,
                            [fieldName]: updatedOptions[fieldName],
                        }))

                        if (fieldName === "linemanager") {
                            const addedManager = updatedOptions[fieldName].find(
                                (manager) => manager.name === newOption
                            )
                            if (addedManager) {
                                onChange(fieldName, addedManager.empid)
                            }
                        } else {
                            onChange(fieldName, newOption)
                        }
                    } else {
                        toast.error("Failed to fetch updated options.")
                    }
                } else {
                    if (fieldName === "linemanager") {
                        toast.error("Employee with this name does not exist!")
                    } else {
                        toast.error(addResponse.message || "Failed to add option.")
                    }
                }
            }
        } catch (error) {
            console.error("Error adding option:", error)
            toast.error("An error occurred while adding the option.")
        } finally {
            setLoader(false)
            handleDialogClose()
        }
    }

    const renderOptions = () => {

        const emptyOption = (
            <MenuItem key="empty" value="">
                <p>None</p>
            </MenuItem>
        )

        if (fieldName === "dept") {
            return [
                emptyOption,
                ...filteredDeptOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                        {option}
                    </MenuItem>
                ))]
        }

        return [
            emptyOption,
            ...dropdownOptions.map((option, index) => (
                <MenuItem key={index} value={option.empid || option}>
                    {option.name || option}
                </MenuItem>
            ))]
    }

    useEffect(() => {
        if (fieldName === "dept" && bankname) {
            const mappedDepts = bankDeptMapping?.[bankname] || []
            setFilteredDeptOptions(mappedDepts)
        }
    }, [bankname, bankDeptMapping, fieldName])


    useEffect(() => {
    }, [fieldName, value, dropdownOptions, bankDeptMapping])

    return (
        <>
            <TextField
                select
                variant="standard"
                value={value || ""}
                onChange={handleSelection}
                fullWidth
            >
                {renderOptions()}
                {tier === 2 && (
                    <MenuItem value="addNew" style={{ fontWeight: "bold" }}>
                        + New Option
                    </MenuItem>
                )}
            </TextField>

            {/* Dialog for adding a new option only for tier 2*/}
            {tier === 2 && (
                <Dialog open={isDialogOpen} onClose={handleDialogClose}
                    sx={{
                        "& .MuiDialog-paper": {
                            width: "300px",
                            maxWidth: "80%",
                        },
                    }}>
                    <DialogTitle>Add New Option</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="New Option"
                            type="text"
                            value={newOption}
                            onChange={(e) => setNewOption(e.target.value)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
                        <Button
                            sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, width: "80px", height: "35px" }}
                            onClick={handleDialogClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddOption}
                            color="primary"
                            disabled={loader}
                            sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, height: "35px" }}
                        >
                            {loader ? (
                                <CircularProgress size={24} style={{ color: "white" }} />
                            ) : (
                                "Add"
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default Dropdown
