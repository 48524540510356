import React, { useEffect, useState } from "react"
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    Button,
} from "@mui/material"
import {
    fetchNewEmpCount,
    fetchNewEmpRecords,
    checkEmpUnderManager,
} from "../../../Actions/commonController"
import SearchBar from "../../SearchBar"
import PaginationTable from "../../Pagination"
import ConfirmationModal from "../../Modals/ConfirmationModal"
import { useNavigate } from "react-router-dom"
import { CircularProgress } from "@mui/material"
import { useAuth } from "../../../Context/authContext"
import { toast } from "react-toastify"

// Modals
import PersonalDetailsModal from "../../Modals/PersonalDetailsModal"
import EmployeeDetailsModal from "../../Modals/EmployeeDetailsModal"
import JoiningDetailsModal from "../../Modals/JoiningDetailsModal"
import VisaDetailsModal from "../../Modals/VisaDetailsModal"
import ContactDetailsModal from "../../Modals/ContactDetailsModal"
import SalaryDetailsModal from "../../Modals/SalaryDetailsModal"
// import ContractDetailsModal from "../../Modals/ContractDetailsModal"
import DocumentDetailsModal from "../../Modals/DocumentDetailsModal"

const columns = [
    { id: "Id", label: "EMPID", minWidth: 40 },
    { id: "name", label: "NAME", minWidth: 40 },
    { id: "personalDetails", label: "PERSONAL DETAILS", minWidth: 80 },
    { id: "employeeDetails", label: "EMPLOYEE DETAILS", minWidth: 80 },
    { id: "joiningDetails", label: "JOINING DETAILS", minWidth: 80 },
    { id: "visaDetails", label: "VISA DETAILS", minWidth: 80 },
    { id: "contactDetails", label: "CONTACT DETAILS", minWidth: 80 },
    { id: "salaryDetails", label: "SALARY DETAILS", minWidth: 80 },
    // { id: "contractDetails", label: "CONTRACT DETAILS", minWidth: 80 },
    { id: "documentDetails", label: "DOCUMENT DETAILS", minWidth: 80 },
    { id: "actions", label: "ACTIONS", minWidth: 50 },
]

export default function NewEmployeeTable() {
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [selectedData, setSelectedData] = useState(null)
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [confirmationAction, setConfirmationAction] = useState("")
    const [confirmationEmpId, setConfirmationEmpId] = useState(null)
    const [confirmationEmail, setConfirmationEmail] = useState(null)
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const [detailsModalOpen, setDetailsModalOpen] = useState("")
    const [loader, setLoader] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)
    const { tier, salaryEdit, eid } = useAuth()

    const navigation = useNavigate()

    const handleSearch = async (searchData, type) => {
        setSearchTerm(searchData)
    }

    const initialization = async () => {
        try {
            let APIData = {
                empid: eid,
                search: searchTerm,
            }
            setLoader(true)
            let { status, data } = await fetchNewEmpCount(APIData)
            if (status === "success") {
                setTotalItems(data)
                loadData()
            }
        } catch (err) {
            console.error("Error in loadData function:", err)
        }
    }

    const loadData = async () => {
        try {
            let APIData = {
                empid: eid,
                quantity: quantity,
                page: page,
                search: searchTerm
            }

            setLoader(true)
            let { status, data } = await fetchNewEmpRecords(APIData)
            if (status === "success") setData(data)
            else console.error("Error fetching employee records:", status)
        } catch (err) {
            console.error("Error in loadData function:", err)
        }
        setLoader(false)
    }


    const handlePageClick = async (newPage) => {
        setPage(newPage)
    }

    const handleItemsPerPageChange = async (newRowsPerPage) => {
        setQuantity(newRowsPerPage)
        setPage(1)
    }

    // const handleButtonClick = (columnId, empId) => {
    //     setSelectedData(empId)
    //     setDetailsModalOpen(columnId)
    // }

    const handleButtonClick = (columnId, empId) => {
        const employee = data.find((item) => item.empid === empId)

        if (!employee) {
            toast.error("Employee data could not be found.")
            return
        }

        console.log("Logged-in User SalaryEdit Permission:", salaryEdit)
        console.log("Employee Bank Name:", employee.bankname)

        if (columnId === "salaryDetails") {
            if (salaryEdit === "edit" || "permisson") {
                setSelectedData(empId)
                setDetailsModalOpen(columnId)
            }
            else if (salaryEdit === employee.bankname) {
                setSelectedData(empId)
                setDetailsModalOpen(columnId)
            } else {
                toast.error("Access denied.")
            }
        } else {
            setSelectedData(empId)
            setDetailsModalOpen(columnId)
        }
    }

    const handleCloseModal = () => {
        setSelectedData(null)
        setDetailsModalOpen("")
    }

    const closeConfirmationModal = (status) => {
        if (status === "success") initialization()
        setConfirmationModalOpen(false)
    }

    const handleConfirmationModal = async (type, empid, email) => {
        setConfirmationAction(type)
        setConfirmationEmpId(empid)
        setConfirmationEmail(email)
        setConfirmationModalOpen(true)
    }

    useEffect(() => {
        if (initialLoad) {
            loadData()
        }
        setInitialLoad(true)
    }, [page, quantity])


    useEffect(() => {
        initialization()
    }, [searchTerm])

    return (
        <Paper className="pagePaper">
            <span className="info">
                <h5>New Employees' Information</h5>
                <SearchBar searchText={"Search by name..."} onSearch={handleSearch} searchType={"name"} />
                <SearchBar searchText={"Search by empid..."} onSearch={handleSearch} searchType={"empid"} />
            </span>

            <div className="row" style={{
                paddingTop: "60px",
            }}>
                <div className="col-md-12">
                    <TableContainer
                        sx={{
                            overflowY: "auto",
                            maxHeight: "500px"
                        }}
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            className="tableHeader"
                                            key={column.id}
                                            align="center"
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    loader ?
                                        <TableRow>
                                            <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                <CircularProgress size={100} />
                                            </TableCell>
                                        </TableRow>
                                        : data && data.length > 0 ? data.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" className="p-0">{item.empid}</TableCell>
                                                <TableCell align="center" className="p-0">{item.name}</TableCell>
                                                {columns.slice(2, columns.length).map((column) => (
                                                    <TableCell className="p-0" key={column.id} align="center">
                                                        {column.id === "actions" ? (
                                                            <div>
                                                                {tier === 2 && (
                                                                    <Button variant="contained" className="btn success" onClick={() => handleConfirmationModal("accept", item.empid, item.email)}>
                                                                        Accept
                                                                    </Button>
                                                                )}
                                                                <Button variant="contained" className="btn warning" onClick={() => navigation(`/employee/edit-employee/${item.empid}`)}>
                                                                    Edit
                                                                </Button>
                                                                <Button variant="contained" className="btn rejected" onClick={() => handleConfirmationModal("reject", item.empid)}>
                                                                    Reject
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <Button variant="contained" className="tableButton" onClick={() => handleButtonClick(column.id, item.empid)}>
                                                                View
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                            :
                                            <TableRow>
                                                <TableCell colSpan={11} style={{ padding: "30px 0", textAlign: "center" }}>
                                                    No results found for "{searchTerm}"
                                                </TableCell>
                                            </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <PaginationTable
                        totalItems={totalItems}
                        itemsPerPage={quantity}
                        onPageChange={handlePageClick}
                        onItemsPerPageChange={handleItemsPerPageChange}
                    />

                </div>
            </div>

            {/* Rendering Modals with their selected row data */}
            <PersonalDetailsModal
                open={detailsModalOpen === "personalDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <EmployeeDetailsModal
                open={detailsModalOpen === "employeeDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <JoiningDetailsModal
                open={detailsModalOpen === "joiningDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <VisaDetailsModal
                open={detailsModalOpen === "visaDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <ContactDetailsModal
                open={detailsModalOpen === "contactDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <SalaryDetailsModal
                open={detailsModalOpen === "salaryDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            {/* <ContractDetailsModal
                open={detailsModalOpen === "contractDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            /> */}

            <DocumentDetailsModal
                open={detailsModalOpen === "documentDetails"}
                onClose={handleCloseModal}
                empId={selectedData}
            />

            <ConfirmationModal
                open={confirmationModalOpen}
                onClose={closeConfirmationModal}
                empId={confirmationEmpId}
                email={confirmationEmail}
                type={confirmationAction}
                message={
                    confirmationAction === "accept"
                        ? { title: "Activate Employee", body: "Enter the date of join below to activate the employee:" }
                        : { title: "Reject Employee", body: "Are you sure you want to reject this employee?" }
                }
            />
        </Paper>
    )
}