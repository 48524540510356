import React, { createContext, useContext, useState } from 'react'
import { SetAuthorizationHeader, removeAuthorizationHeader } from '../Config/axiosInterceptor'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false)
  const [token, setToken] = useState(null)
  const [tier, setTier] = useState(0)
  const [salaryEdit, setSalaryEdit] = useState(null)
  const [username, setUsername] = useState("")
  const [eid, setEid] = useState("")

  const login = async(newToken, tier, sedit, username, eid) => {
    localStorage.setItem("ttk", newToken)
    setUsername(username)
    setEid(eid)
    setIsLogin(true)
    setToken(newToken)
    setTier(tier)
    setSalaryEdit(sedit)
    await SetAuthorizationHeader(newToken)
  }

  const logout = () => {
    localStorage.removeItem("ttk")

    setUsername("")
    setIsLogin(false)
    setToken(null)
    setTier(0)
    setSalaryEdit(null)
    setEid("")
    removeAuthorizationHeader()
  }

  return (
    <AuthContext.Provider value={{ isLogin, token, tier, salaryEdit, username, eid, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}