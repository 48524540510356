import axios from "axios"
import { CreateToken } from "../Components/Utilities/CreateToken"
import { removeAuthorizationHeader } from "../Config/axiosInterceptor"

export const checkLogin = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/login`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            data: response.data
        }
    } catch (err) {
        if (err.response.status === "401") {
            return {
                status: "failed",
                message: "Authentication failed: Incorrect Username / Password"
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status",
            message: err.message
        }
    }
}

export const changePass = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/changePassword`,
            data: { token: CreateToken(data) }
        })

        return {
            status: "success",
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status === "401") {
            return {
                status: "failed",
                message: "Authentication failed"
            }
        }
        return {
            status: "failed",
            error: "Internal Error Occured: Failed fetching server status",
            message: err.message
        }
    }
}

export const checkAuth = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/checkAuth',
            data: { token: CreateToken(data) }
        })
        return {
            status: "success",
            username: response.data.data.username,
            salaryedit: response.data.data.salaryedit,
            tier: response.data.data.tier,
            eid: response.data.data.eid
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const welcome = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/welcome`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchDropdownOptions = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchDropdownOptions`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const addDropdownOption = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/addDropdownOption',
            data: { token: CreateToken(data) }
        })

        return {
            status: response.data.status,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const addEmp = async (data, fileData) => {
    let formData = new FormData()

    formData.append('token', CreateToken(data))
    for (const key in fileData) {
        if (Array.isArray(fileData[key])) {
            fileData[key].forEach(file => {
                formData.append(key, file)
            })
        } else {
            formData.append(key, fileData[key])
        }
    }
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/addNewEmployee`,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData
        })

        return {
            status: response.data.status,
            message: response.data.message,
        }
    } catch (err) {
        return {
            status: "failed",
            message: err.message
        }
    }
}

export const empCheck = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/empCheck`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpPaySlipsCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpPaySlipsCount`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchAllEmployeeCounts = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchAllEmployeeCounts`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchAllEmployeeRecords = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchAllEmployeeRecords',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchNewEmpRecords = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchNewEmployeeRecords',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchActiveEmpCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchActiveEmployeeCounts`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status === 401) {
            // logout()
            return {
                status: "failed",
                message: "Session expired"
            }
        }
        return {
            status: "failed"
        }
    }
}

export const fetchActiveEmpRecords = async (data, logout) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchActiveEmployeeRecords',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status === "401") {
            await removeAuthorizationHeader()
            await logout()
            return {
                status: "failed",
                message: "Session expired"
            }
        }

        return {
            status: "failed"
        }
    }
}

export const fetchInactiveEmpCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchInactiveEmployeeCounts`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchInactiveEmpRecords = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchInactiveEmployeeRecords',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchDropoutEmployeeCounts = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchDropoutEmployeeCounts`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchDropoutEmployeeRecords = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchDropoutEmployeeRecords',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const checkEmpUnderManager = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/checkEmpUnderManager',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchTotalEmp = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchTotalEmp`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {

        return {
            status: "failed"
        }
    }
}

export const fetchNewMonthlyHires = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchNewMonthlyHires`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {

        return {
            status: "failed"
        }
    }
}

export const fetchPendingChanges = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchPendingChanges`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {

        return {
            status: "failed"
        }
    }
}

export const updatePendingChanges = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/updatePendingChanges`,
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {

        return {
            status: "failed"
        }
    }
}

export const activeEmp = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/activeEmp',
            data: { token: CreateToken(data) }
        })

        return {
            status: response.data.status,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const rejectEmp = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/rejectEmp',
            data: { token: CreateToken(data) }
        })

        return {
            status: response.data.status,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const terminateEmp = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/terminateEmp',
            data: { token: CreateToken(data) }
        })

        return {
            status: response.data.status,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const markEmpAsAbsconded = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/markEmpAsAbsconded',
            data: { token: CreateToken(data) }
        })

        return {
            status: response.data.status,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const revokeTermination = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/revokeTermination',
            data: { token: CreateToken(data) }
        })

        return {
            status: response.data.status,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpPersonalDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpPersonalDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {

        return {
            status: "failed"
        }
    }
}

export const fetchEmpDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpJoiningDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpJoiningDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpVisaDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpVisaDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpContactDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpContactDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpSalaryDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpSalaryDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpDocumentDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpDocumentDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
            docs: response.data.docs
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpContractDetails = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmpContractDetails`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {

        return {
            status: "failed"
        }
    }
}

export const fetchSingleEmpRecord = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchSingleEmpRecord',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
            docs: response.data.docs
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const updateEmpRecords = async (data, fileData) => {
    let formData = new FormData()

    formData.append('token', CreateToken(data))
    for (const key in fileData) {
        if (Array.isArray(fileData[key])) {
            fileData[key].forEach(file => {
                formData.append(key, file)
            })
        } else {
            formData.append(key, fileData[key])
        }
    }

    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/updateEmpRecords',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const checkEmpExist = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/checkEmpExist',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
            employee: response.data.employee,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const deleteDocs = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/deleteDocs',
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmployeesForLeaveApplication = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmployeesForLeaveApplication`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchExistingLeaveBalance = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchExistingLeaveBalance`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const submitLeaveApplication = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/submitLeaveApplication',
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        if (err.response.status === 401) {

        }
        if (err.response.status === 400) {
            return {
                status: err.response.data.status,
                error: err.response.data.message
            }
        }
        return {
            status: "failed",
            error: "Failed to submit leave application. (Contact Administrator)"
        }
    }
}

export const fetchLeaveApplicationCounts = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchLeaveApplicationCounts`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status === 401) {
            // logout()
            return {
                status: "failed",
                message: "Session expired"
            }
        }
        return {
            status: "failed"
        }
    }
}

export const fetchLeaveApplications = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchLeaveApplications`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const changeLeaveStatus = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/changeLeaveStatus`,
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.message
        }
    }
}

export const skipPendingApprover = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/skipPendingApprover`,
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.message
        }
    }
}

export const changeEndDate = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/changeEndDate`,
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.message
        }
    }
}

export const fetchLeaveHistoryCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchLeaveHistoryCount`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status === 401) {
            // logout()
            return {
                status: "failed",
                message: "Session expired"
            }
        }
        return {
            status: "failed"
        }
    }
}

export const fetchLeaveHistory = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchLeaveHistory`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            message: err.message
        }
    }
}

export const cancelLeave = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/cancelLeave',
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchLineManager = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchLineManager`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchTrackHistory = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchTrackHistory',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmployeesUnderManager = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchEmployeesUnderManager',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpsUnderManagersCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchEmpsUnderManagersCount',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchTrackLeaves = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchTrackLeaves',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchSalaryCertifHistory = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchSalaryCertifHistory',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const applySalaryCert = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/applySalaryCert',
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.message
        }
    }
}

export const fetchEmployeesForSalaryCertificate = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchEmployeesForSalaryCertificate`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchSalaryCertificatesCounts = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchSalaryCertificatesCounts`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        if (err.response.status === 401) {
            // logout()
            return {
                status: "failed",
                message: "Session expired"
            }
        }
        return {
            status: "failed"
        }
    }
}

export const fetchSalaryCertificates = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchSalaryCertificates',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const handleSalaryCert = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/handleSalaryCert`,
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.message
        }
    }
}

export const fetchEmpSalaryCertificates = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchEmpSalaryCertificates',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const skipPendingSalaryApprover = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/skipPendingSalaryApprover`,
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.message
        }
    }
}

export const cancelSalaryCertificateRequest = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': 'https://fmapi.fintrekmarketing.com:2083/cancelSalaryCertificateRequest',
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const downloadSalaryCertificate = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/downloadSalaryCertificate',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpPaySlips = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchEmpPaySlips',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchNewEmpCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': `https://fmapi.fintrekmarketing.com:2083/fetchNewEmployeeCounts`,
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const updateLWD = async (data) => {
    try {
        let response = await axios({
            'method': 'post',
            'url': `https://fmapi.fintrekmarketing.com:2083/updateLWD`,
            data: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            message: response.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.message
        }
    }
}

export const fetchEmployeeReports = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchEmployeeReports',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpReportCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchEmpReportCount',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchEmpReportFilterCount = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchEmpReportFilterCount',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const downloadEmployeeReports = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/downloadEmployeeReports',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const fetchExpiryDates = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/fetchExpiryDates',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
            counts: response.data.counts,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const downloadExperienceLetter = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/downloadExperienceLetter',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}

export const downloadTerminationLetter = async (data) => {
    try {
        let response = await axios({
            'method': 'get',
            'url': 'https://fmapi.fintrekmarketing.com:2083/downloadTerminationLetter',
            params: { token: CreateToken(data) }
        })
        return {
            status: response.data.status,
            data: response.data.data,
        }
    } catch (err) {
        return {
            status: "failed"
        }
    }
}