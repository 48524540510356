import { React, useState, useEffect } from "react"
import {
  Grid,
  Paper,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  styled,
  CircularProgress,
  Typography,
  Tabs,
  Tab,
  Badge,
  Select,
  MenuItem,
} from "@mui/material"
import {
  fetchTotalEmp,
  fetchNewMonthlyHires,
  applySalaryCert,
  fetchExpiryDates,
  fetchLeaveApplications,
  fetchSalaryCertificates,
  fetchEmployeesForSalaryCertificate,
  fetchPendingChanges,
  updatePendingChanges,
} from "../Actions/commonController"
import LaunchIcon from '@mui/icons-material/Launch'
import { useAuth } from "../Context/authContext"
import { toast } from "react-toastify"
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications'
import EmployeeSelectionForm from "./Tables/EmployeeTables/EmployeeSelectionForm"
import nationalities from "../json/nationalities.json"

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.toLocaleString("default", { month: "long" })
  const year = date.getFullYear()

  const suffix = (d) => {
    if (d > 3 && d < 21) return "th"
    switch (d % 10) {
      case 1: return "st"
      case 2: return "nd"
      case 3: return "rd"
      default: return "th"
    }
  }

  return `${day}${suffix(day)} ${month}, ${year}`
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "5px",
  fontFamily: "Open Sans",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
}))

const RowContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing("20px"),
}))

const Divider = styled("hr")({
  height: "4px",
  borderTop: "1px solid #ccc",
  background: "linear-gradient(to right, #00cc00, #00ff00)",
})

export default function DashoardAdmin() {

  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(1000)
  const [salaryCertOpen, setSalaryCertOpen] = useState(false)
  const [toWhom, setToWhom] = useState("")
  const [country, setCountry] = useState("")
  const [selectedEmpId, setSelectedEmpId] = useState("")
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState("")
  const [expiryLoader, setExpiryLoader] = useState(false)
  const [leavesLoader, setleavesLoader] = useState(false)
  const [salaryCertificates, setSalaryCertificates] = useState([])
  const [loadingCertificates, setLoadingCertificates] = useState(false)
  const [filteredExpiryDates, setFilteredExpiryDates] = useState([])
  const [pendingChanges, setPendingChanges] = useState({})
  const [leaveRequests, setLeaveRequests] = useState([])
  const [expiryDates, setExpiryDates] = useState([])
  const [expiryType, setExpiryType] = useState("all")
  const [counts, setCounts] = useState({
    passportExpiryCount: 0,
    workPermitExpiryCount: 0,
    visaExpiryCount: 0,
    medicalExpiryCount: 0,
  })
  const [activeEmpCount, setActiveEmpCount] = useState(0)
  const [newEmpCount, setNewEmpCount] = useState(0)
  const { eid } = useAuth()
  const [activeTab, setActiveTab] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleClickOpen = () => {
    setSalaryCertOpen(true)
    fetchEmployees()
  }

  const handleClose = () => {
    setSalaryCertOpen(false)
    setReason("")
    setToWhom("")
    setCountry("")
    setSelectedEmpId("")
  }

  const handleOpenLeaveFormModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseLeaveFormModal = () => {
    setIsModalOpen(false)
  }

  const fetchEmployees = async () => {
    try {
      let APIData = {
        empid: eid
      }
      const { status, data } = await fetchEmployeesForSalaryCertificate(APIData)
      if (status === "success") {
        setEmployees(data)
      } else {
        console.error("Failed to load employees.", status)
      }
    } catch (error) {
      console.error("Error fetching employees:", error)
    }
  }

  const handleConfirm = async () => {
    try {
      if (!selectedEmpId || !reason || !toWhom || !country) {
        toast.error("Please fill in all fields.")
        return
      }
      let APIData = {
        empid: selectedEmpId,
        reason: reason,
        towhom: toWhom,
        country: country,
      }
      setLoadingCertificates(true)
      const { status } = await applySalaryCert(APIData)
      if (status === "success") {
        toast.success("Salary certificate request sent successfully!")
        handleClose()
        fetchPendingSalaryCertificates()
      } else {
        toast.error("Request is already placed!")
      }
    } catch (error) {
      toast.error("Error requesting salary certificate! (Contact administrator")
    }
    setLoadingCertificates(false)
  }

  const fetchPendingLeaves = async () => {
    try {
      setleavesLoader(true)
      let APIData = {
        empid: eid,
        page,
        quantity,
        status: "pending",
        search: "",
        leaveType: "all",
      }
      const { status, data } = await fetchLeaveApplications(APIData)
      if (status === "success") {
        const pendingForCurrentUser = data.filter((request) => {
          const pendingList = request.pendingapproval ? request.pendingapproval.split(",") : []
          return pendingList[0] === eid.toString()
        })
        setLeaveRequests(pendingForCurrentUser)
      } else {
        console.error("Failed to fetch pending leave requests:", status)
      }
    } catch (error) {
      console.error("Error fetching pending leave requests:", error)
    }
    setleavesLoader(false)
  }

  const fetchPendingSalaryCertificates = async () => {
    try {
      let APIData = {
        empid: eid,
        status: "pending",
        search: "",
        quantity: quantity,
        page: page,
      }
      setLoadingCertificates(true)
      const { status, data } = await fetchSalaryCertificates(APIData)
      if (status === "success") {
        const pendingForCurrentUser = data.filter((certificate) => {
          const pendingList = certificate.pendingapproval ? certificate.pendingapproval.split(",") : []
          return pendingList[0] === eid.toString()
        })
        setSalaryCertificates(pendingForCurrentUser)
      } else {
        console.error("Failed to fetch salary certificates", status)
      }
    } catch (error) {
      console.error("Error fetching salary certificates", error)
    } setLoadingCertificates(false)
  }

  const calculateDaysLeft = (expiryDate) => {
    const today = new Date()
    const expiry = new Date(expiryDate)
    const daysDiff = Math.ceil((expiry - today) / (1000 * 60 * 60 * 24))

    if (daysDiff > 60) {
      return null
    }
    return daysDiff
  }

  const generateExpiryMessages = (employee) => {
    const { empid, name, passportexpiry, workpermitexpiry, visaexpiry, medicalexpiry } = employee
    const messages = []

    const addMessage = (type, expiryDate) => {
      if (!expiryDate) return
      const daysDiff = calculateDaysLeft(expiryDate)
      if (daysDiff === null) return

      if (daysDiff === null) return

      const isExceeded = daysDiff < 0
      const absDays = Math.abs(daysDiff)

      const daysText = daysDiff === 0
        ? "Expires today"
        : `${absDays} days ${isExceeded ? "exceeded" : "left"}`

      const typeDisplayName = {
        passport: "passport",
        workPermit: "work permit",
        visa: "visa",
        medical: "medical insurance",
      }[type] || type

      messages.push({
        type,
        name: `EmpID #${empid} - ${name} (${daysText})`,
        empid,
        daysLeft: daysDiff,
        status: isExceeded ? "Exceeded" : daysDiff === 0 ? "Today" : "Upcoming",
        message: daysDiff === 0
          ? `${name}'s ${typeDisplayName} <b>expires today</b>.`
          : `${name}'s ${typeDisplayName} ${isExceeded
            ? `expired <b>${absDays} days ago</b>`
            : `will expire in <b>${absDays} days</b>`
          } on <b>${formatDate(expiryDate)}</b>.`,
      })
    }

    addMessage("passport", passportexpiry)
    addMessage("workPermit", workpermitexpiry)
    addMessage("visa", visaexpiry)
    addMessage("medical", medicalexpiry)

    return messages
  }

  const fetchAndFilterExpiryDates = async () => {
    try {
      setExpiryLoader(true)
      const APIData = {
        empid: eid,
        expiryType: expiryType
      }
      const { status, data, counts } = await fetchExpiryDates(APIData)

      if (status === "success") {
        const allMessages = data.flatMap(generateExpiryMessages)

        const filteredMessages =
          expiryType === "all"
            ? allMessages
            : allMessages.filter((msg) => msg.type === expiryType)

        const sortedMessages = filteredMessages.sort((a, b) => {
          if (a.status === "Exceeded" && b.status === "Upcoming") return -1 
          if (a.status === "Upcoming" && b.status === "Exceeded") return 1  

          return a.daysLeft - b.daysLeft
        })

        setExpiryDates(sortedMessages)

        setCounts({
          passport: (counts?.passport?.upcoming || 0) + (counts?.passport?.exceeded || 0),
          workPermit: (counts?.workPermit?.upcoming || 0) + (counts?.workPermit?.exceeded || 0),
          visa: (counts?.visa?.upcoming || 0) + (counts?.visa?.exceeded || 0),
          medical: (counts?.medical?.upcoming || 0) + (counts?.medical?.exceeded || 0),
        })
      } else {
        toast.error("Failed to fetch expiry dates! (Contact Administrator)")
      }
    } catch (err) {
      console.error("Error fetching expiry dates:", err)
    } finally {
      setExpiryLoader(false)
    }
  }


  const fetchActiveEmployeeCounts = async () => {
    try {
      setLoading(true)

      const { status, data } = await fetchTotalEmp()
      if (status === "success") {
        setActiveEmpCount(data)
      } else {
        console.error("Failed to fetch active employee count")
      }
    } catch (error) {
      console.error("Error fetching employee counts:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchNewMonthlyEmployeeCounts = async () => {
    try {
      setLoading(true)

      const { status, data } = await fetchNewMonthlyHires()
      if (status === "success") {
        setNewEmpCount(data)
      } else {
        console.error("Failed to fetch new employee count")
      }
    } catch (error) {
      console.error("Error fetching employee counts:", error)
    } finally {
      setLoading(false)
    }
  }

  const applyFilter = (expiryDates, filterType) => {
    if (filterType === "all") {
      setFilteredExpiryDates(expiryDates)
    } else {
      setFilteredExpiryDates(expiryDates.filter((e) => e.type === filterType))
    }
  }

  const fetchChangeRequests = async () => {
    try {
      setLoading(true)

      let APIData = {
        empid: eid
      }
      const { status, data } = await fetchPendingChanges(APIData)
      if (status === "success") {
        setPendingChanges(data)
      } else {
        console.error("Failed to fetch pending changes data")
      }
    } catch (err) {
      console.error("Error fetching pending changes data:", err.message)
    } finally {
      setLoading(false)
    }
  }

  const handlePendingChanges = async (id, decision) => {
    try {
      setLoading(true)

      let APIData = {
        id,
        decision
      }
      const { status, data } = await updatePendingChanges(APIData)
      if (status === "success") {
        fetchChangeRequests()
        toast.success("Changes updated successfully!")
      } else {
        toast.error("Error submitting pending change, please try again.")
      }
    } catch (err) {
      toast.error("Error submitting pending change, please try again.")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNewMonthlyEmployeeCounts()
    fetchActiveEmployeeCounts()
    fetchPendingLeaves()
    fetchPendingSalaryCertificates()
    fetchChangeRequests()
  }, [])

  useEffect(() => {
    fetchAndFilterExpiryDates()
  }, [expiryType])

  const leaveCount = leaveRequests.length
  const salaryCount = salaryCertificates.length

  return (
    <Box>
      {/* row 1 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">TOTAL NO. OF EMPLOYEES</h2>
                <h4>{loading ? <CircularProgress size={24} /> : activeEmpCount}</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">MONTHLY NEW HIRES</h2>
                <h4>{loading ? <CircularProgress size={24} /> : newEmpCount}</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">DEPARTMENTS</h2>
                <h4>-</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
              </div>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <h2 className="p-0">NO. OF OPEN POSITIONS</h2>
                <h4>-</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 2 */}
      <RowContainer>
        <Grid container spacing={2}>
          {/* Main content */}
          <Grid item xs={9}>
            <Item sx={{ height: "450px", padding: "25px 15px" }}>
              <div className="cards">
                <Box display="flex" alignItems="center" paddingBottom={"10px"}>
                  <CircleNotificationsIcon sx={{ color: "#1976d2", fontSize: "25px", marginRight: "10px" }} />
                  <h2 className="p-0" style={{ margin: 0 }}>NOTIFICATIONS</h2>
                </Box>
              </div>
              <Box>
                {/* Tabs for Expiry Date Reminders and Leave Requests */}
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="Tabs inside the box"
                  TabIndicatorProps={{
                    sx: {
                      backgroundColor: '#1976d2',
                      height: '2px',
                    },
                  }}
                  sx={{
                    borderBottom: "1px solid #e0e0e0",
                    ".MuiTab-root": {
                      fontSize: "14px",
                      color: "black",
                      "&.Mui-selected": {
                        color: "#1976d2",
                      },
                    },
                    ".MuiTabs-flexContainer": {
                      justifyContent: "left",
                    },
                  }}
                >
                  <Tab
                    label={
                      <Badge badgeContent={Object.values(counts).reduce((a, b) => a + b, 0)} color="error">
                        EXPIRY DATES
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge badgeContent={leaveCount} color="error">
                        LEAVES
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge badgeContent={salaryCount} color="error">
                        SALARY CERTIFICATES
                      </Badge>
                    }
                  />
                  {/* <Tab label="BIRTHDAYS" /> */}
                </Tabs>

                {/* Expiry Date Reminders */}
                {activeTab === 0 && (
                  <Box>
                    <Box p={2}>
                      {/* Filter Buttons */}
                      {/* <Box display="flex" justifyContent="center" mb={2} borderBottom={1} borderColor="divider">
                        <Button
                          variant="text"
                          onClick={() => setExpiryType("all")}
                          sx={{
                            color: expiryType === "all" ? "#2596BE" : "inherit",
                            borderBottom: expiryType === "all" ? "2px solid #2596BE" : "none",
                            marginRight: "10px",
                            fontSize: "12px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#2596BE",
                              borderBottom: "2px solid #2596BE",
                            },
                          }}
                        >
                          All ({allCount})
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => setExpiryType("passportexpiry")}
                          sx={{
                            color: expiryType === "passportexpiry" ? "#2596BE" : "inherit",
                            borderBottom: expiryType === "passportexpiry" ? "2px solid #2596BE" : "none",
                            marginRight: "10px",
                            fontSize: "12px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#2596BE",
                              borderBottom: "2px solid #2596BE",
                            },
                          }}
                        >
                          Passport Expiry ({counts.passport})
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => setExpiryType("workpermitexpiry")}
                          sx={{
                            color: expiryType === "workpermitexpiry" ? "#2596BE" : "inherit",
                            borderBottom: expiryType === "workpermitexpiry" ? "2px solid #2596BE" : "none",
                            marginRight: "10px",
                            fontSize: "12px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#2596BE",
                              borderBottom: "2px solid #2596BE",
                            },
                          }}
                        >
                          Work Permit Expiry ({counts.workPermitExpiryCount})
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => setExpiryType("visaexpiry")}
                          sx={{
                            color: expiryType === "visaexpiry" ? "#2596BE" : "inherit",
                            borderBottom: expiryType === "visaexpiry" ? "2px solid #2596BE" : "none",
                            marginRight: "10px",
                            fontSize: "12px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#2596BE",
                              borderBottom: "2px solid #2596BE",
                            },
                          }}
                        >
                          Visa Expiry ({counts.visaExpiryCount})
                        </Button>
                        <Button
                          variant="text"
                          onClick={() => setExpiryType("medicalexpiry")}
                          sx={{
                            color: expiryType === "medicalexpiry" ? "#2596BE" : "inherit",
                            borderBottom: expiryType === "medicalexpiry" ? "2px solid #2596BE" : "none",
                            fontSize: "12px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "#2596BE",
                              borderBottom: "2px solid #2596BE",
                            },
                          }}
                        >
                          Medical Expiry ({counts.medicalExpiryCount})
                        </Button>
                      </Box> */}

                      <Box display="flex" justifyContent="center" mb={2}>
                        {["all", "passport", "workPermit", "visa", "medical"].map((type) => (
                          <Button
                            key={type}
                            variant="text"
                            onClick={() => setExpiryType(type)}
                            sx={{
                              color: expiryType === type ? "#2596BE" : "inherit",
                              borderBottom: expiryType === type ? "2px solid #2596BE" : "none",
                              marginRight: "10px",
                              fontSize: "12px",
                            }}
                          >
                            {type === "all"
                              ? `All (${Object.values(counts).reduce((a, b) => a + b, 0)})`
                              : `${type.charAt(0).toUpperCase() + type.slice(1)} (${counts[type] || 0})`}
                          </Button>
                        ))}
                      </Box>

                      {/* Expiry Date Reminders */}
                      {expiryLoader ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%" padding="20px">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box className="notification-container">
                          {expiryDates.length === 0 ? (
                            <Typography variant="body1" color="textSecondary" align="center" paddingTop="100px">
                              No upcoming expiry dates.
                            </Typography>
                          ) : (
                            expiryDates.map((item, index) => (
                              <Box key={index} mb={2}>
                                <Paper
                                  elevation={3}
                                  sx={{
                                    padding: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    borderLeft: "5px solid red",
                                    backgroundColor: "#fdf5f5",
                                    animation: "pulse 1s infinite",
                                  }}
                                >
                                  <Box flexGrow={1}>
                                    <Typography variant="body1" color="textSecondary"
                                      sx={{ fontWeight: "bold", fontSize: "15px", color: "#ff4d4d" }}>
                                      {item.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                      <span dangerouslySetInnerHTML={{ __html: item.message }}></span>
                                    </Typography>
                                  </Box>
                                </Paper>
                              </Box>
                            ))
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}

                {/* Leave Requests */}
                {activeTab === 1 && (
                  <Box p={2} sx={{ maxHeight: "300px", overflow: 'auto' }}>
                    {leavesLoader ? (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress size={40} />
                      </Box>
                    ) : (
                      leaveRequests.length > 0 ? (
                        leaveRequests.map((request) => (
                          <Box key={request.id} mb={2}>
                            <Paper
                              elevation={3}
                              sx={{
                                padding: 2,
                                display: "flex",
                                alignItems: "center",
                                borderLeft: "5px solid #1976d2",
                                backgroundColor: "#f0f8ff",
                                animation: "pulse 1s infinite",
                              }}
                            >
                              <Box flexGrow={1}>
                                <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "15px", color: "#1976d2" }}>
                                  EmpID #{request.empid} - {request.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {`${request.name} applied for ${request.type} leave. `}
                                  {request.acceptedNames
                                    ? `It has been accepted by ${request.acceptedNames}. `
                                    : "It has been accepted by no one yet. "}
                                  Your action is pending!
                                </Typography>
                              </Box>
                            </Paper>
                          </Box>
                        ))
                      ) : (
                        <Typography variant="body1" color="textSecondary" align="center">
                          No pending leave requests.
                        </Typography>
                      )
                    )}
                  </Box>
                )}

                {/* Salary Certificate Requests */}
                {activeTab === 2 && (
                  <Box p={2} sx={{ maxHeight: "300px", overflow: "auto" }}>
                    {loadingCertificates ? (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress size={40} />
                      </Box>
                    ) : (
                      salaryCertificates.length > 0 ? (
                        salaryCertificates.map((certificate) => (
                          <Box key={certificate.id} mb={2}>
                            {/* <Typography variant="body1">{certificate.name}</Typography>
                            <Typography variant="body2">Reason: {certificate.reason}</Typography>
                            <Typography variant="body2">Status: Pending Approval</Typography> */}
                            <Paper
                              elevation={3}
                              sx={{
                                padding: 2,
                                display: "flex",
                                alignItems: "center",
                                borderLeft: "5px solid #4CAF50",
                                backgroundColor: "#f0fff4",
                                animation: "pulse 1s infinite",
                              }}
                            >
                              <Box flexGrow={1}>
                                <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: "15px", color: "#4CAF50" }}>
                                  EmpID #{certificate.empid} - {certificate.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {`${certificate.name} requested for a salary certificate. `}
                                  {certificate.acceptedNames
                                    ? `It has been accepted by ${certificate.acceptedNames}. `
                                    : "It has been accepted by no one yet. "}
                                  Your action is pending!
                                </Typography>
                              </Box>
                            </Paper>
                          </Box>
                        ))

                      ) : (
                        <Typography variant="body1" color="textSecondary" align="center">
                          No pending salary certificate requests.
                        </Typography>
                      )
                    )}
                  </Box>
                )}
              </Box>
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item sx={{ height: "450px", padding: "25px 15px" }}>
              <div className="cards">
                <Box display="flex" alignItems="center">
                  <LaunchIcon sx={{ color: "#1976d2", fontSize: "25px", marginRight: "10px" }} />
                  <h2 className="p-0" style={{ margin: 0 }}>QUICK LINKS</h2>
                </Box>
                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleOpenLeaveFormModal}
                  >
                    Request Leave Application
                  </Button>
                  <EmployeeSelectionForm open={isModalOpen} onClose={handleCloseLeaveFormModal} />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleClickOpen}
                  >
                    Request Salary Certificate
                  </Button>
                </span>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 3 */}
      {
        pendingChanges && pendingChanges.length > 0 &&
        <RowContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item sx={{ height: "150px", padding: "25px 15px" }}>
                <div className="cards">
                  <Box display="flex" alignItems="center" paddingBottom={"10px"}>
                    <CircleNotificationsIcon sx={{ color: "#1976d2", fontSize: "25px", marginRight: "10px" }} />
                    <h2 className="p-0" style={{ margin: 0 }}>CHANGE ALERTS</h2>
                  </Box>

                  {
                    pendingChanges.map((item) => (
                      <Box flexGrow={1}>
                        <b>{item.author}</b> has made the following changes in salary for <b>{item.name}</b>
                        <br />
                        <Button
                          variant="contained"
                          className="btn"
                          onClick={() => { }}
                        >
                          Info
                        </Button>
                        <Button
                          variant="contained"
                          className="btn success"
                          onClick={() => { handlePendingChanges(item.id, "accept") }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="contained"
                          className="btn rejected"
                          onClick={() => { handlePendingChanges(item.id, "reject") }}
                        >
                          Reject
                        </Button>
                      </Box>
                    ))
                  }
                </div>
              </Item>
            </Grid>
          </Grid>
        </RowContainer>
      }

      {/* Request Salary Certificate Button */}
      <Dialog open={salaryCertOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Request Salary Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill out the form to request a salary certificate.
          </DialogContentText>

          <Select
            value={selectedEmpId}
            onChange={(e) => setSelectedEmpId(e.target.value)}
            fullWidth
            displayEmpty
            sx={{ mt: 1, mb: 1 }}
          >
            <MenuItem value="" disabled>Select Employee</MenuItem>
            {employees.map((employee) => (
              <MenuItem key={employee.EmployeeID} value={employee.EmployeeID}>
                {employee.EmployeeName}
              </MenuItem>
            ))}
          </Select>
          <Box display="flex" gap={2} sx={{ mt: 1, mb: 1 }}>
            <TextField
              label="To"
              value={toWhom}
              onChange={(e) => setToWhom(e.target.value)}
              fullWidth
              sx={{ flex: 1 }}
            />
            <Select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              displayEmpty
              fullWidth
              sx={{ flex: 1 }}
            >
              <MenuItem value="" disabled>Select Country</MenuItem>
              {nationalities.map((nat, index) => (
                <MenuItem key={index} value={nat}>{nat}</MenuItem>
              ))}
            </Select>
          </Box>
          <TextField
            label="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            multiline
            rows={3}
            sx={{ mt: 1, mb: 1 }}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
          <Button
            sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, width: "80px", height: "35px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, width: "80px", height: "35px" }}
            onClick={handleConfirm}
            disabled={loadingCertificates}
          >
            {loadingCertificates ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}