import React, { useEffect, useState } from "react"
import {
  Paper,
  CircularProgress,
  TableContainer,
  Box,
  FormControl,
  TextField,
} from "@mui/material"
import {
  getGridDateOperators,
  getGridStringOperators,
  DataGrid,
  useGridApiRef,
} from "@mui/x-data-grid"
import {
  fetchEmployeeReports,
  fetchEmpReportCount,
  fetchEmpReportFilterCount,
} from "../../Actions/commonController"
import { toast } from "react-toastify"
import CustomToolbar from "../../Components/Utilities/CustomToolbar"
import ResponsiveDatePicker from "../../Components/DatePicker/ResponsiveDatePicker"
import moment from "moment"
import PaginationTable from "../../Components/Pagination"
import { useAuth } from "../../Context/authContext"
import { filter } from "jszip"

const FIELD_LABELS = {
  empid: 'Emp ID',
  name: 'Name',
  email: 'Email',
  gender: 'Gender',
  nationality: 'Nationality',
  dob: 'Date of Birth',
  age: "Age",
  passportno: 'Passport No.',
  passportstatus: "Passport Status",
  passportexpiry: 'Passport Expiry',
  passportissuingcountry: 'Passport Issuing Country',
  passportissuestate: 'Passport Issue State',
  contactuae: 'Contact UAE',
  maritalstatus: 'Marital Status',
  religion: 'Religion',
  hiredfrom: 'Hired From',
  dateofjoin: 'Date of Join (Actual)',
  designation: 'Designation',
  officeloc: 'Office Location',
  bankname: 'Bank Name',
  dept: 'Department',
  // division: 'Division',
  sourcecode: 'Source Code',
  linemanager: 'Line Manager',
  teamleader: 'Team Leader',
  visaid: 'UID',
  dateofjoinmol: 'MOL Start Date',
  workpermitnumber: 'Work Permit Number',
  workpermitstartdate: "Work Permit Start Date",
  workpermitexpiry: 'Work Permit Expiry',
  personalcodemohre: 'Personal Code (MOHRE)',
  contracttype: 'Contract Type',
  sponsorname: 'Sponsor Name',
  labourcard: 'Labour Card Under',
  visafileno: 'Visa File No.',
  visadesignation: 'Visa Designation',
  visatype: 'Visa Type',
  visastatus: 'Visa Status',
  visacancellation: 'Visa Cancellation Date',
  visaexpiry: 'Visa Expiry',
  eidno: 'Emirates ID No.',
  gpssa: "GPSSA",
  insuredno: "Insured No.",
  insureddate: 'Insured Date',
  contacthome: 'Contact Home',
  emergencycontactuaename: 'Emergency Contact UAE Name',
  emergencycontactuaeno: 'Emergency Contact UAE No.',
  emergencycontacthomename: 'Emergency Contact Home Name',
  emergencycontacthomeno: 'Emergency Contact Home No.',
  companysimnumber: 'Company SIM Number',
  simissuedate: 'SIM Issue Date',
  simlastdate: 'SIM Last Date',
  simplan: 'SIM Plan',
  empstatus: 'Employee Status',
  // absconded: "Absconded",
  statusremarks: 'Status Remarks',
  lwd: 'Last Working Day',
  eed: 'Effective End Date',
  medicalprovider: 'Medical Provider',
  medicalexpiry: 'Medical Expiry',
  medicalcardno: 'Medical Card No.',
  basicsalary: 'Basic Salary',
  housingallowance: 'Housing Allowance',
  transportallowance: 'Transport Allowance',
  foodallowance: 'Food Allowance',
  mobileallowance: 'Mobile Allowance',
  otherallowance: 'Other Allowance',
  totalsalary: 'Total Salary',
  contractsalary: 'Actual Salary',
  accnumber: 'Bank Account Number',
  iban: 'IBAN',
  salarybankname: 'Salary Bank Name',
  docstatus: 'Document Status',
  remarks: 'Remarks',
  createdAt: 'Created At',
  createdBy: 'Created By',
  previousempemail: 'Previous Employee Email',
  verificationstatus: 'Verification Status',
  emailsenton: 'Email Sent On',
  repliedon: 'Email Replied On'
}

const VISIBLE_FIELDS = [
  'empid',
  'name',
  'email',
  'gender',
  'nationality',
  'dob',
  'age',
  'passportno',
  'passportstatus',
  'passportexpiry',
  'passportissuingcountry',
  'passportissuestate',
  'contactuae',
  'maritalstatus',
  'religion',
  'hiredfrom',
  'dateofjoin',
  'designation',
  'officeloc',
  'bankname',
  'dept',
  // 'division',
  'sourcecode',
  'linemanager',
  'teamleader',
  'visaid',
  'dateofjoinmol',
  'workpermitnumber',
  'workpermitstartdate',
  'workpermitexpiry',
  'personalcodemohre',
  'contracttype',
  'sponsorname',
  'labourcard',
  'visafileno',
  'visadesignation',
  'visatype',
  'visastatus',
  'visacancellation',
  'visaexpiry',
  'eidno',
  'gpssa',
  'insuredno',
  'insureddate',
  'contacthome',
  'emergencycontactuaename',
  'emergencycontactuaeno',
  'emergencycontacthomename',
  'emergencycontacthomeno',
  'companysimnumber',
  'simissuedate',
  'simlastdate',
  'simplan',
  'empstatus',
  // 'absconded',
  'statusremarks',
  'lwd',
  'eed',
  'medicalprovider',
  'medicalexpiry',
  'medicalcardno',
  'basicsalary',
  'housingallowance',
  'transportallowance',
  'foodallowance',
  'mobileallowance',
  'otherallowance',
  'totalsalary',
  'contractsalary',
  'accnumber',
  'iban',
  'salarybankname',
  'docstatus',
  'remarks',
  'createdAt',
  'createdBy',
  'previousempemail',
  'verificationstatus',
  'emailsenton',
  'repliedon'
]

const SALARY_FIELDS = [
  'basicsalary',
  'housingallowance',
  'transportallowance',
  'foodallowance',
  'mobileallowance',
  'otherallowance',
  'totalsalary',
  'contractsalary',
]

const dateFields = [
  'dob',
  'passportexpiry',
  'dateofjoin',
  'dateofjoinmol',
  // 'workpermitstartdate',
  'workpermitexpiry',
  'visaexpiry',
  'lwd',
  'eed',
  'medicalexpiry',
  'visacancellation',
  'simissuedate',
  'simlastdate',
  'insureddate',
  'createdAt',
  'emailsenton',
  'repliedon'
]

const dateBetweenOperator = {
  label: 'between',
  value: 'between',
  getApplyFilterFn: (filterItem) => {
    if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
      return null
    }
    if (!filterItem.value[0] || !filterItem.value[1]) {
      return null
    }
    return (value) => {
      const valueDate = moment(value).format('YYYY-MM-DD')
      return (
        valueDate >= moment(filterItem.value[0], 'DD/MM/YYYY').format('YYYY-MM-DD') &&
        valueDate <= moment(filterItem.value[1], 'DD/MM/YYYY').format('YYYY-MM-DD')
      )
    }
  },
  InputComponent: (props) => {
    const [start, setStart] = useState(props.item.value ? props.item.value[0] : '')
    const [end, setEnd] = useState(props.item.value ? props.item.value[1] : '')

    const handleStartDateChange = (event) => {
      const newValue = [event.target.value, end]
      setStart(event.target.value)
      if (newValue[0] && newValue[1]) {
        props.applyValue({ ...props.item, value: newValue })
      }
    }

    const handleEndDateChange = (event) => {
      const newValue = [start, event.target.value]
      setEnd(event.target.value)
      if (newValue[0] && newValue[1]) {
        props.applyValue({ ...props.item, value: newValue })
      }
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', paddingTop: "16px" }}>
        <FormControl sx={{ flex: 1 }}>
          <ResponsiveDatePicker
            className="employeeForm"
            value={start}
            handleDate={handleStartDateChange}
            format="DD/MM/YYYY"
            renderInput={(params) => (
              <TextField {...params} size="small" sx={{ width: '100px' }} />
            )}
          />
        </FormControl>
        /
        <FormControl sx={{ flex: 1 }}>
          <ResponsiveDatePicker
            className="employeeForm"
            value={end}
            handleDate={handleEndDateChange}
            format="DD/MM/YYYY"
            renderInput={(params) => (
              <TextField {...params} size="small" sx={{ width: '100px' }} />
            )}
          />
        </FormControl>
      </Box>
    )
  },
}

const dateOperators = getGridDateOperators().filter(
  (operator) => ['is', 'before', 'after'].includes(operator.value)
).map((operator) => {
  return {
    ...operator,
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null
      }
      return (value) => {
        const valueDate = moment(value).format('YYYY-MM-DD')
        const filterDate = moment(filterItem.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
        switch (operator.value) {
          case 'is':
            return valueDate === filterDate
          case 'before':
            return valueDate < filterDate
          case 'after':
            return valueDate > filterDate
          default:
            return true
        }
      }
    },
    InputComponent: (props) => (
      <Box sx={{ alignItems: 'center', width: '100%', paddingTop: '16px' }}>
        <ResponsiveDatePicker
          className="employeeForm"
          {...props}
          name={props.item.columnField}
          label={props.item.operatorValue}
          handleDate={(event) => props.applyValue({ ...props.item, value: event.target.value })}
          value={props.item.value ? moment(props.item.value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
        />
      </Box>
    ),
  }
})

dateOperators.push(dateBetweenOperator)

const stringOperators = getGridStringOperators().filter(
  (operator) => ['contains', 'equals', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty'].includes(operator.value)
)

export default function EmployeeReports() {

  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [columns, setColumns] = useState([])
  const [quantity, setQuantity] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const apiRef = useGridApiRef()
  const { tier, eid, salaryEdit } = useAuth()
  const [selectedColumns, setSelectedColumns] = useState(
    VISIBLE_FIELDS.filter(
      (field) => !(tier === 3 && salaryEdit === "notallowed" && SALARY_FIELDS.includes(field))
    )
  )
  const [initialLoad, setInitialLoad] = useState(false)
  const [filters, setFilters] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [filterModel, setFilterModel] = useState({
    items: [],
  })

  const initialization = async () => {
    try {
      setLoader(true)

      let APIData = {
        empid: eid,
        filters: filterModel.items.map((item) => ({
          columnField: item.field,
          operatorValue: item.operator,
          value: Array.isArray(item.value)
            ? item.value.map(date =>
              moment(date, 'DD/MM/YYYY').isValid()
                ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : date
            )
            : moment(item.value, 'DD/MM/YYYY').isValid()
              ? moment(item.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
              : item.value,
        })),
      }

      let { status, data } = await fetchEmpReportCount(APIData)

      if (status === "success") {
        setTotalItems(data)
        await fetchReports()
      }
    } catch (err) {
      console.error("Error in initialization function", err)
    } finally {
      setLoader(false)
    }
  }

  const fetchReports = async () => {
    try {

      setLoader(true)

      // if (selectedColumns.length === 0) {
      //   setData([])
      //   setColumns([])
      //   return
      // }

      const filteredColumns = selectedColumns.filter((field) => {
        if (SALARY_FIELDS.includes(field)) {
          return salaryEdit !== "notallowed"
        }
        return true
      })

      const APIData = {
        empid: eid,
        quantity: quantity,
        page: page,
        fields: filteredColumns.join(','),
        filters: filterModel.items.map((item) => ({
          columnField: item.field,
          operatorValue: item.operator,
          value: Array.isArray(item.value)
            ? item.value.map(date =>
              moment(date, 'DD/MM/YYYY').isValid()
                ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : date
            )
            : moment(item.value, 'DD/MM/YYYY').isValid()
              ? moment(item.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
              : item.value,
        })),
      }

      const { data, status } = await fetchEmployeeReports(APIData)

      if (status === 'success') {
        setData(data)

        const updatedColumns = selectedColumns.filter((field) => {
          if (SALARY_FIELDS.includes(field)) {
            return salaryEdit !== "notallowed"
          }
          return true
        })

        setColumns(generateColumns(updatedColumns))
      } else {
        throw new Error('Failed to fetch reports')
      }
    } catch (error) {
      console.error('Error fetching employee reports', error)
      toast.error('Error fetching employee reports')
    } finally {
      setLoader(false)
      setDataLoaded(true)
    }
  }

  const generateColumns = (fields) => {
    return fields.map((field) => ({
      field,
      headerName: FIELD_LABELS[field] || field.toUpperCase(),
      flex: 1,
      minWidth: 100,
      type: field === 'empid' ? 'string' : undefined,
      renderCell: dateFields.includes(field)
        ? (params) => {
          const rawValue = params.value
          if (!rawValue) return ""

          const formattedDate = moment(rawValue, "DD/MM/YYYY", true)
          return formattedDate.isValid()
            ? formattedDate.format("DD/MM/YYYY")
            : ""
        }
        : undefined,
      filterOperators: dateFields.includes(field) ? dateOperators : stringOperators,
    }))
  }

  const handlePageClick = async (newPage) => {
    setPage(newPage)
  }

  const handleItemsPerPageChange = async (newRowsPerPage) => {
    setQuantity(newRowsPerPage)
    setPage(1)
  }

  const handleColumnVisibilityChange = (params) => {
    const { field, isVisible } = params

    setSelectedColumns((prevColumns) => {
      if (isVisible) {
        return [...prevColumns, field]
      } else {
        return prevColumns.filter((col) => col !== field)
      }
    })
  }

  const handleApplyFilters = async () => {
    try {
      const APIData = {
        // tier: tier,
        empid: eid,
        filters: filters.map((item) => ({
          columnField: item.field,
          operatorValue: item.operator,
          value: Array.isArray(item.value)
            ? item.value.map(date => moment(date, 'DD/MM/YYYY').isValid() ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : date)
            : moment(item.value, 'DD/MM/YYYY').isValid() ? moment(item.value, 'DD/MM/YYYY').format('YYYY-MM-DD') : item.value
        })),
      }


      const { data, status } = await fetchEmpReportFilterCount(APIData)
      if (status === 'success') {
        setTotalItems(data)
        setPage(1)
        setFilterModel({ items: filters })
      } else {
        toast.error('Error fetching filtered report count')
      }
    } catch (error) {
      console.error('Error applying filters:', error)
      toast.error('Error applying filters')
    }
  }

  const handleRemoveAllFilters = async () => {
    setFilters([{ field: '', operator: '', value: '' }])
    setFilterModel({ items: [] })
    setPage(1)
    try {
      let APIData = {
        tier: tier,
        empid: eid,
      }
      let { status, data } = await fetchEmpReportCount(APIData)
      if (status === "success") {
        setTotalItems(data)
      }
    } catch (error) {
      console.error('Error fetching full report data:', error)
      toast.error('Error fetching unfiltered report data')
    }
  }

  const CustomNoRowsOverlay = ({ selectedColumns, filterApplied, dataLoaded, loader }) => {
    if (loader || !dataLoaded) {
      return null
    }

    if (selectedColumns.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          No Rows.
        </Box>
      )
    }

    if (filterApplied && selectedColumns.length > 0 && data.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          No Results Found.
        </Box>
      )
    }

    return null
  }


  useEffect(() => {
    initialization()
  }, [])

  useEffect(() => {
    if (initialLoad) fetchReports()
    setInitialLoad(true)
  }, [filterModel, page, quantity, selectedColumns])

  return (
    <Paper className="pagePaper"
      sx={{
        width: '100%',
        maxWidth: '90%',
        boxSizing: 'border-box'
      }}
    >
      <span className="info">
        <h5>Employee Reports</h5>
      </span>

      <div className="row" style={{
        paddingTop: '30px',
        overflowX: "auto"
      }}>
        <div className="col-md-12">
          <TableContainer
            sx={{
              overflowY: "auto",
              overflowZ: "auto",
              width: "100%",
            }}
          >
            <DataGrid
              className="data-grid-filter-toolbar"
              sx={{
                width: '100%',
                height: '550px',
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'nowrap',
                },
              }}
              apiRef={apiRef}
              rows={data}
              columns={columns.map((col) => ({
                ...col,
                flex: 1,
              }))}
              getRowId={(row) => row.empid}
              disableColumnMenu
              disableColumnResize={false}
              loading={loader}
              components={{
                NoRowsOverlay: () => (
                  <CustomNoRowsOverlay
                    selectedColumns={selectedColumns}
                    filterApplied={filterModel.items.length > 0}
                    dataLoaded={dataLoaded}
                  />
                ),
                Toolbar: CustomToolbar,
              }}
              slots={{
                toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  filters: filters,
                  setFilters: setFilters,
                  handleApplyFilters: handleApplyFilters,
                  handleRemoveAllFilters: handleRemoveAllFilters,
                  columns: columns,
                  selectedColumns: selectedColumns,
                  setSelectedColumns: setSelectedColumns,
                  apiRef: apiRef,
                  setData: setData,
                },
              }}
              onColumnVisibilityChange={handleColumnVisibilityChange}
              paginationMode="server"
              hideFooterPagination
              rowCount={totalItems}
              onPageChange={handlePageClick}
              page={page}
              pageSize={quantity}
            />

            {/* {loader && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                sx={{ zIndex: 1 }}
              >
                <CircularProgress size={50} />
              </Box>
            )} */}

            <PaginationTable
              totalItems={totalItems}
              itemsPerPage={quantity}
              onPageChange={handlePageClick}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </TableContainer>
        </div>
      </div>
    </Paper>
  )
}