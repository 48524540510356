import React, { useState, useEffect } from "react"
import {
  Grid,
  Paper,
  Box,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Autocomplete,
  Modal,
  Typography,
  styled,
  InputAdornment,
} from "@mui/material"
import {
  deleteDocs,
  fetchSingleEmpRecord,
  updateEmpRecords,
  fetchDropdownOptions,
  checkEmpUnderManager,
} from "../../Actions/commonController"
import {
  useParams,
  Link,
  useNavigate
} from "react-router-dom"
import DragNDrop from "../dragNDrop"
import { toast } from "react-toastify"
import DeleteIcon from "@mui/icons-material/Delete"
import nationalities from "../../json/nationalities.json"
import countryCodes from "../../json/countryCodes.json"
import { useAuth } from "../../Context/authContext"
import { AdjustFilename } from "../Utilities/FilenameAdjuster"
import { Validate } from "../Validations/EditEmployeeValidation"
import { FormatDate } from "../Utilities/DateFormatter"
import ResponsiveDatePicker from "../DatePicker/ResponsiveDatePicker"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Dropdown from "../Modals/Dropdown"

const initialValue = {
  name: "",
  email: "",
  gender: "",
  nationality: "",
  dob: "",
  age: "",
  passportno: "",
  passportexpiry: "",
  passportissuingcountry: "",
  passportissuestate: "",
  passportstatus: "",
  contactuae: "",
  maritalstatus: "",
  religion: "",
  photoloc: [],
  eidno: "",
  gpssa: "",
  insuredno: "",
  insureddate: "",
  dateofjoin: "",
  designation: "",
  officeloc: "",
  bankname: "",
  dept: "",
  division: "",
  hiredfrom: "",
  sourcecode: "",
  linemanager: "",
  teamleader: "",
  visaid: "",
  dateofjoinmol: "",
  workpermitnumber: "",
  workpermitstartdate: "",
  workpermitexpiry: "",
  personalcodemohre: "",
  contracttype: "",
  sponsorname: "",
  labourcard: "",
  visafileno: "",
  visadesignation: "",
  visatype: "",
  visastatus: "",
  visaexpiry: "",
  visacancellation: "",
  contacthome: "",
  emergencycontactuaename: "",
  emergencycontactuaeno: "",
  emergencycontacthomename: "",
  emergencycontacthomeno: "",
  companysimnumber: "",
  simissuedate: "",
  simlastdate: "",
  simplan: "",
  medicalprovider: "",
  medicalexpiry: "",
  medicalcardno: "",
  basicsalary: "",
  housingallowance: "",
  transportallowance: "",
  foodallowance: "",
  mobileallowance: "",
  otherallowance: "",
  totalsalary: "",
  contractsalary: "",
  accnumber: "",
  iban: "",
  salarybankname: "",
  folderid: "",
  remarks: "",
  contractupdate: [],
  docslist: [],
  docstatus: "",
  previousempemail: "",
  verificationstatus: "",
  emailsenton: "",
  repliedon: "",
  lwd: "",
  eed: "",
  statusremarks: "",
}

const documentMappingInitial = {
  photo: {
    name: "Photo",
    keywords: ["photo"]
  },
  passportCopy: {
    name: "Passport Copy",
    keywords: ["passport"]
  },
  attestedDegreeCertificate: {
    name: "Attested Degree Certificate or Highest Qualification Certificate",
    keywords: ["attested", "degree", "qualification"]
  },
  visitVisaCopy: {
    name: "Visit Visa Copy or Visa Cancellation Documents",
    keywords: ["visit visa", "visa cancellation", "current visa", "visa copy", "employment visa"]
  },
  experienceCertificate: {
    name: "Experience Certificate or Acceptance of Resignation Letter",
    keywords: ["experience certificate", "experience letter", "resignation"]
  },
  emiratesID: {
    name: "Emirates ID",
    keywords: ["emirates id", "eid"]
  },
  validHomeCountryID: {
    name: "Valid Home Country ID",
    keywords: ["home country id", "pakistan", "india", "egypt"]
  },
  cv: {
    name: "CV",
    keywords: ["cv"]
  },
  tradeLicense: {
    name: "Trade License",
    keywords: ["trade license"]
  },
  policeClearance: {
    name: "Police Clearance",
    keywords: ["police", "clearance"]
  },
  eMF: {
    name: "eMF",
    keywords: ["emf"]
  },
  sponsorPassport: {
    name: "Sponsor Passport",
    keywords: ["sponsor passport"]
  },
  sponsorVisaCopy: {
    name: "Sponsor Visa Copy",
    keywords: ["sponsor visa"]
  },
  noc: {
    name: "NOC in Arabic addressing Fintrek Marketing",
    keywords: ["noc", "fintrek marketing"]
  },
}

const defaultDocumentList = Object.keys(documentMappingInitial)

const TableWrapper = styled(TableContainer)(({ theme }) => ({
  overflowY: "scroll",
  margin: "0",
}))

export default function EditEmployeeForm({ status }) {
  const [formValue, setFormValue] = useState(initialValue)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [uploadedDocs, setUploadedDocs] = useState([])
  const [uploadedImage, setUploadedImage] = useState("")
  const [lineManagers, setLineManagers] = useState([])
  const [overwriteModal, setOverwriteModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [changeLogs, setChangeLogs] = useState({})
  const [formValidation, setFormValidation] = useState({})
  const [empStatus, setEmpStatus] = useState("")
  const [newTotalSalary, setNewTotalSalary] = useState(null)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const navigate = useNavigate()
  const { empid } = useParams()
  const { eid, tier, salaryEdit } = useAuth()
  const [newDoc, setNewDoc] = useState("")
  const [getCountryCode, setGetCountryCode] = useState("+")
  const [requiredDocuments, setRequiredDocuments] = useState([])
  const [tempUploadedFiles, setTempUploadedFiles] = useState([])
  const [tempUploadedDocs, setTempUploadedDocs] = useState([])
  const [isUnderManager, setIsUnderManager] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState({
    dept: [],
    bankname: [],
    sponsorname: [],
    linemanager: [],
  })

  const {
    name, email, gender, nationality, dob, age, passportno, passportstatus, passportexpiry, passportissuingcountry,
    passportissuestate, contactuae, maritalstatus, religion, photoloc, hiredfrom, dateofjoin, designation, officeloc,
    bankname, dept, division, sourcecode, linemanager, teamleader, visaid, dateofjoinmol, workpermitnumber,
    workpermitstartdate, workpermitexpiry, personalcodemohre, contracttype, sponsorname, labourcard,
    visafileno, visadesignation, visatype, visastatus, visaexpiry, visacancellation, eidno, gpssa, insuredno,
    insureddate, contacthome, emergencycontactuaename, emergencycontactuaeno, emergencycontacthomename,
    emergencycontacthomeno, companysimnumber, simissuedate, simlastdate, simplan, medicalprovider,
    medicalexpiry, medicalcardno, basicsalary, housingallowance, transportallowance, foodallowance,
    mobileallowance, otherallowance, totalsalary, contractsalary, accnumber, iban, salarybankname,
    folderid, contractupdate, remarks, previousempemail, verificationstatus, emailsenton, repliedon,
    lwd, eed, statusremarks
  } = formValue || {}

  const restrictedFields = [
    "basicsalary",
    "housingallowance",
    "transportallowance",
    "foodallowance",
    "mobileallowance",
    "otherallowance",
    "totalsalary",
    "contractsalary",
    "accnumber",
    "iban",
    "salarybankname",
    ...formValue.contractupdate.map((_, index) => [
      `contractupdate${index}.basic`,
      `contractupdate${index}.housing`,
      `contractupdate${index}.transport`,
      `contractupdate${index}.food`,
      `contractupdate${index}.mobile`,
      `contractupdate${index}.allowance`,
      // `contractupdate${index}.contracts`,
      `contractupdate${index}.total`
    ]).flat()
  ]

  const numberFields = [
    "contactuae",
    "contacthome",
    "emergencycontactuaeno",
    "emergencycontacthomeno",
    "companysimnumber",
    "basicsalary",
    "housingallowance",
    "transportallowance",
    "foodallowance",
    "mobileallowance",
    "otherallowance",
    "contractsalary",
  ]

  const fetchData = async (empid) => {
    try {
      let APIData = { empid: empid }

      if (empid) {
        let { data, status, docs } = await fetchSingleEmpRecord(APIData)

        if (status === "success") {

          const calculateAge = (dob) => {
            if (typeof dob !== "string" || !dob.includes("/")) {
              return ""; // Return empty or a default value if dob is not valid
            }

            const [day, month, year] = dob.split("/").map((part) => parseInt(part, 10));
            if (!day || !month || !year) {
              return ""
            }

            const birthDate = new Date(year, month - 1, day);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();

            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }

            return age.toString();
          };

          const formattedDob = data.dob ? FormatDate(data.dob) : ""
          const age = calculateAge(formattedDob)

          const docslist = data.docslist ? data.docslist.split(";").map((doc) => doc.trim()) : []

          const formattedData = {
            ...data,
            dob: formattedDob,
            age: age,
            passportexpiry: data.passportexpiry ? FormatDate(data.passportexpiry) : "",
            dateofjoin: data.dateofjoin ? FormatDate(data.dateofjoin) : "",
            dateofjoinmol: data.dateofjoinmol ? FormatDate(data.dateofjoinmol) : "",
            workpermitstartdate: data.workpermitstartdate ? FormatDate(data.workpermitstartdate) : "",
            workpermitexpiry: data.workpermitexpiry ? FormatDate(data.workpermitexpiry) : "",
            visaexpiry: data.visaexpiry ? FormatDate(data.visaexpiry) : "",
            medicalexpiry: data.medicalexpiry ? FormatDate(data.medicalexpiry) : "",
            visacancellation: data.visacancellation ? FormatDate(data.visacancellation) : "",
            simissuedate: data.simissuedate ? FormatDate(data.simissuedate) : "",
            simlastdate: data.simlastdate ? FormatDate(data.simlastdate) : "",
            insureddate: data.insureddate ? FormatDate(data.insureddate) : "",
            emailsenton: data.emailsenton ? FormatDate(data.emailsenton) : "",
            repliedon: data.repliedon ? FormatDate(data.repliedon) : "",
            lwd: data.lwd ? FormatDate(data.lwd) : "",
            eed: data.eed ? FormatDate(data.eed) : "",
            docslist: docslist,
            contractupdate: data.contractupdate ? JSON.parse(data.contractupdate).map(contract => ({
              ...contract,
              date: contract.date ? FormatDate(contract.date) : ""
            })) : [],
          }

          setFormValue(formattedData)
          setUploadedDocs(docs || [])
          setUploadedImage(data.photoloc)
          setEmpStatus(data.empstatus)
          setRequiredDocuments(formattedData.docslist)
        } else {
          console.error("Fetch status not successful:", status)
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error)
    }
  }

  const getDropDownOptions = async () => {
    try {
      const { status, data } = await fetchDropdownOptions()
      if (status === "success") {
        setDropdownOptions(data)
      } else {
        toast.error("Failed to fetch dropdown options")
      }
    }
    catch (error) {
      console.error("Error fetching dropdown options:", error)
    }
  }

  useEffect(() => {
    if (empid != "" && eid != "" && tier != "") fetchData(empid)
  }, [empid, eid, tier])

  useEffect(() => {
  }, [empStatus])


  useEffect(() => {
    getDropDownOptions()
  }, [])

  useEffect(() => {
    const totalSalary = (
      parseFloat(basicsalary || "0") +
      parseFloat(housingallowance || "0") +
      parseFloat(transportallowance || "0") +
      parseFloat(foodallowance || "0") +
      parseFloat(mobileallowance || "0") +
      parseFloat(otherallowance || "0")
    ).toFixed(2)

    setFormValue(prevState => ({
      ...prevState,
      totalsalary: totalSalary.toString()
    }))
  }, [
    basicsalary,
    housingallowance,
    transportallowance,
    foodallowance,
    mobileallowance,
    otherallowance,
    formValue.contractupdate
  ])

  useEffect(() => {
    if (Array.isArray(formValue.docslist) && formValue.docslist.length > 0) {
      setRequiredDocuments(formValue.docslist)
    } else {
      setRequiredDocuments([])
    }
  }, [formValue.docslist])

  useEffect(() => {
    const updatedStatus = checkDocumentsStatus([...tempUploadedFiles, ...uploadedDocs], requiredDocuments)
    setFormValue((prevState) => ({
      ...prevState,
      docstatus: updatedStatus,
    }))
  }, [uploadedDocs, requiredDocuments, tempUploadedFiles])

  useEffect(() => {
    const updatedCountryCode = countryCodes[formValue.nationality] || "+"
    setGetCountryCode(updatedCountryCode)
  }, [formValue.nationality])

  const checkDocumentsStatus = (uploadedFiles, requiredDocs) => {
    const uploadedFileNames = uploadedFiles.map((file) => normalizeString(file.filename || file.name))

    const missingDocuments = requiredDocs.filter(docName => {
      const normalizedDocName = normalizeString(docName)
      const docEntry = Object.values(documentMappingInitial).find(doc => normalizeString(doc.name) === normalizedDocName)

      if (!docEntry) {
        console.warn(`Document key ${docName} not found in documentMapping`)
        return false
      }

      const keywords = (docEntry.keywords || []).map(normalizeString)
      return !uploadedFileNames.some(fileName => keywords.some(keyword => fileName.includes(keyword)))
    })

    const docStatus = missingDocuments.length === 0 ? "Submitted" : "Pending"
    return docStatus
  }

  const closeOverwiteModal = () => { setOverwriteModal(false) }

  const handleChange = (e) => {
    let { name, value } = e.target

    if (name === "name") {
      const alphabetRegex = /^[A-Za-z\s]*$/
      if (!alphabetRegex.test(value)) {
        return
      }
    }

    if (numberFields.includes(name)) {
      const numberRegex = /^[0-9+()\-\s]*$/
      if (!numberRegex.test(value)) {
        return
      }
    }

    const calculateAge = (dob) => {
      if (!dob) return ''
      const [day, month, year] = dob.split('/')
      const birthDate = new Date(`${year}-${month}-${day}`)
      const today = new Date()
      let age = today.getFullYear() - birthDate.getFullYear()
      const monthDiff = today.getMonth() - birthDate.getMonth()
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age.toString()
    }

    if (name === "dob") {
      const parsedDob = typeof value === "string" ? value : "";
      const calculatedAge = calculateAge(parsedDob);
      setFormValue((prevValues) => ({
        ...prevValues,
        dob: parsedDob,
        age: calculatedAge,
      }));
      if (calculatedAge && parseInt(calculatedAge) < 18) {
        toast.error("Employee must be at least 18 years old");
      }
    } else {
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }))
    }

    if (!changeLogs[name]) {
      setChangeLogs((prevLogs) => ({
        ...prevLogs,
        [name]: {
          before: formValue[name],
          after: value,
        },
      }))
    } else {
      setChangeLogs((prevLogs) => ({
        ...prevLogs,
        [name]: {
          ...prevLogs[name],
          after: value,
        },
      }))
    }
  }

  const handleDropdownChange = (fieldName, selectedValue) => {
    setFormValue((prev) => ({
      ...prev,
      [fieldName]: selectedValue,
    }));
  };

  const handleConfirmChange = () => {
    const currentTotalSalary = (
      parseFloat(basicsalary) +
      parseFloat(housingallowance) +
      parseFloat(transportallowance) +
      parseFloat(foodallowance) +
      parseFloat(mobileallowance) +
      parseFloat(otherallowance)
    ).toFixed(2)

    const difference = parseFloat(newTotalSalary) - parseFloat(currentTotalSalary)

    if (!isNaN(difference)) {
      setFormValue((prevState) => ({
        ...prevState,
        otherallowance: (parseFloat(prevState.otherallowance) + difference),
        totalsalary: parseFloat(newTotalSalary),
      }))
    } else {
      console.error("Invalid difference calculation.")
    }

    setConfirmationModal(false)
  }

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === "totalsalary") {
      const parsedValue = parseFloat(value)
      if (!isNaN(parsedValue)) {
        setNewTotalSalary(parsedValue)
        setConfirmationModal(true)
      }
      else {
        console.error("Invalid total salary input.")
      }
    } else {
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }))
    }
  }

  const handleCancelChange = () => {
    setConfirmationModal(false)
  }

  const handleNationalityChange = (event, newValue) => {
    setFormValue({
      ...formValue,
      nationality: newValue
    })
  }

  const handlePassportIssuingCountryChange = (event, newValue) => {
    setFormValue({
      ...formValue,
      passportissuingcountry: newValue || "",
    })
  }

  const handleDrop = (acceptedFiles) => {
    let newTempUploadedFiles = [...tempUploadedFiles]
    let newTempUploadedDocs = [...tempUploadedDocs]

    acceptedFiles.forEach(file => {
      let adjustedFilename = AdjustFilename(file.name, tempUploadedFiles)
      let newFile = new File([file], adjustedFilename, { type: file.type })
      newTempUploadedFiles.push(newFile)
      newTempUploadedDocs.push({ filename: adjustedFilename })
    })

    setTempUploadedFiles(newTempUploadedFiles)
    setTempUploadedDocs(newTempUploadedDocs)
    updateDocumentStatus(newTempUploadedFiles.concat(uploadedDocs))
  }

  const handleRemoveFile = (index) => {
    const updatedFiles = [...tempUploadedFiles]
    updatedFiles.splice(index, 1)
    setTempUploadedFiles(updatedFiles)
    setTempUploadedDocs(updatedFiles.map((file) => ({ filename: file.name })))
    updateDocumentStatus(updatedFiles)
  }

  const handleSubmit = async () => {

    let duplicatesDetected = false;

    tempUploadedFiles.forEach((newFile) => {
      const duplicate = uploadedDocs.some(existingDoc => existingDoc.filename === newFile.name);
      if (duplicate) {
        duplicatesDetected = true;
      }
    });

    if (duplicatesDetected) {
      setOverwriteModal(true);
      return;
    }
    performSubmit();
  };

  const performSubmit = async () => {
    const logsObject = {};
    Object.entries(changeLogs).forEach(([key, { before, after }]) => {
      logsObject[key] = { before, after };
    });

    const logsJson = JSON.stringify(logsObject);
    let checkValidate = Validate(formValue);
    setFormValidation(checkValidate);

    if (Object.keys(checkValidate).length > 0) {
      toast.error("Errors: " + Object.entries(checkValidate).map(([field, message]) => `${message}`).join(", "));
      return;
    }

    let updatedDocslist = formValue.docslist;
    updatedDocslist = updatedDocslist.filter(doc => requiredDocuments.includes(doc));
    requiredDocuments.forEach(doc => {
      if (!updatedDocslist.includes(doc)) {
        updatedDocslist.push(doc);
      }
    });

    setUploadedFiles(tempUploadedFiles);
    setUploadedDocs([...uploadedDocs, ...tempUploadedDocs]);

    setTempUploadedFiles([]);
    setTempUploadedDocs([]);

    handleFinalSubmit(logsJson, updatedDocslist, tempUploadedDocs);
  }

  const handleFinalSubmit = async (logsJson, updatedDocslist, tempUploadedDocs) => {
    try {

      if (age < 18) {
        toast.error("Employee must be at least 18 years old")
        return
      }

      if (!Array.isArray(requiredDocuments)) {
        console.error("requiredDocuments is not an array")
        return
      }

      const logsObject = JSON.stringify(changeLogs)

      setLoader(true)
      let APIData = {
        ...formValue,
        // otherallowance: otherallowance || "0",
        // totalsalary: totalsalary || "0",
        contractupdate: Array.isArray(formValue.contractupdate) ? JSON.stringify(formValue.contractupdate.map(value => value || "0")) : "[]",
        // logs: logsJson,
        logs: logsObject,
        empid: empid,
        author: eid,
        // eid: eid,
        docslist: updatedDocslist.join("; "),
        salaryedit: salaryEdit
      }

      let APIFiles = {
        documents: tempUploadedFiles,
        photoloc: photoloc,
      }

      let checkValidate = Validate(APIData)
      setFormValidation(checkValidate)
      if (Object.entries(checkValidate).length > 0) {
        toast.error("Error in the following fields: " + Object.entries(checkValidate).map(([field, message]) => `${message}`).join(", "))
        return
      }

      const { status } = await updateEmpRecords(APIData, APIFiles)
      if (status === "success") {
        toast.success("The record has been edited!")
        if (empStatus === "New") {
          navigate("/employee/new-employee-list")
        } else {
          navigate("/employee/existing-employee-list")
        }
        fetchData(empid)
        setUploadedFiles([])
        setUploadedImage([])
        setChangeLogs({})
      } else {
        toast.error("The record is not edited!")
      }
    } catch (error) {
      console.error("Error updating employee record:", error)
    } finally {
      setLoader(false)
    }
  }

  const handleDeleteDocument = async (fname) => {
    try {

      let APIData = {
        folderid: folderid,
        filename: fname
      }
      let { status } = await deleteDocs(APIData)
      if (status === "success") {
        toast.success("Following document has been deleted: " + fname)
        fetchData(empid)
        setUploadedFiles([])
      }
    } catch (err) {
      console.error("Error updating employee record:", err.message)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && isValidFileType(file)) {
      setFormValue({ ...formValue, photoloc: file.name })
    } else {
      console.warn("Invalid file selected")
    }
  }

  const isValidFileType = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"]
    return allowedTypes.includes(file.type)
  }

  const handleNavigateToTrackHistory = () => {
    navigate(`/employee/track-history/${empid}`)
  }

  const incrementContract = () => {

    const today = new Date()
    const formattedData = today.toLocaleDateString("en-GB")

    setFormValue(prevState => ({
      ...prevState,
      contractupdate: [
        {
          date: formattedData,
          basic: "0",
          housing: "0",
          transport: "0",
          food: "0",
          mobile: "0",
          allowance: "0",
          // contracts: "0",
          total: "0",
        },
        ...prevState.contractupdate,
      ]
    }))
  }

  const handleContractUpdate = (index) => (event) => {
    const { name, value } = event.target;
    const fieldName = name.split(".").pop();
    let updatedContracts = [...formValue.contractupdate];

    if (!updatedContracts[index]) {
      updatedContracts[index] = {};
    }

    updatedContracts[index][fieldName] = value;

    // Calculate total based on the fields
    const basic = parseFloat(updatedContracts[index].basic || 0);
    const housing = parseFloat(updatedContracts[index].housing || 0);
    const transport = parseFloat(updatedContracts[index].transport || 0);
    const food = parseFloat(updatedContracts[index].food || 0);
    const mobile = parseFloat(updatedContracts[index].mobile || 0);
    const allowance = parseFloat(updatedContracts[index].allowance || 0);

    updatedContracts[index].total = (
      basic +
      housing +
      transport +
      food +
      mobile +
      allowance
    ).toFixed(2); // Ensures total is always displayed with 2 decimal points

    setFormValue((prevState) => ({
      ...prevState,
      contractupdate: updatedContracts,
    }));
  };


  const handleContractRemove = index => {
    setFormValue(prevState => ({
      ...prevState,
      contractupdate: prevState.contractupdate.filter((_, i) => i !== index)
    }))
  }

  useEffect(() => {
    setRequiredDocuments(defaultDocumentList)
  }, [])

  const normalizeString = (str) => {
    if (typeof str !== "string") return ""
    const fileNameWithoutExtension = str.replace(/\.[^/.]+$/, "")
    return fileNameWithoutExtension.trim().toLowerCase().replace(/[^a-z0-9]/g, '')
  }

  const isDocumentUploaded = (docName) => {
    const normalizedDocName = normalizeString(docName)

    const docEntry = Object.values(documentMappingInitial).find(doc => normalizeString(doc.name) === normalizedDocName)

    if (docEntry) {
      const keywords = docEntry.keywords.map(normalizeString)

      const isUploadedByKeywords = uploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return keywords.some((keyword) => fileNameLower.includes(keyword))
      }) || tempUploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return keywords.some((keyword) => fileNameLower.includes(keyword))
      }) || tempUploadedFiles.some((uploadedFile) => {
        const fileNameLower = normalizeString(uploadedFile.name)
        return keywords.some((keyword) => fileNameLower.includes(keyword))
      })

      const isExactMatch = uploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedFiles.some((uploadedFile) => {
        const fileNameLower = normalizeString(uploadedFile.name)
        return fileNameLower === normalizedDocName
      })

      return isUploadedByKeywords || isExactMatch
    } else {
      const isExactMatch = uploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedDocs.some((uploadedDoc) => {
        const fileNameLower = normalizeString(uploadedDoc.filename)
        return fileNameLower === normalizedDocName
      }) || tempUploadedFiles.some((uploadedFile) => {
        const fileNameLower = normalizeString(uploadedFile.name)
        return fileNameLower === normalizedDocName
      })

      return isExactMatch
    }
  }

  const addRequiredDocument = () => {
    if (newDoc.includes(";")) {
      toast.error("Semicolons are not allowed in document names.")
    } else if (newDoc && !requiredDocuments.includes(newDoc)) {
      const newKey = newDoc
      const updateDocuments = [...requiredDocuments, newKey]
      setRequiredDocuments(updateDocuments)
      setNewDoc("")
      updateDocumentStatus([...tempUploadedFiles, ...uploadedDocs])
    }
  }

  const removeRequiredDocument = (docKey) => {
    const updateDocuments = requiredDocuments.filter((doc) => doc !== docKey)
    setRequiredDocuments(updateDocuments)
    updateDocumentStatus([...tempUploadedFiles, ...uploadedDocs])
  }

  const updateDocumentStatus = (uploadedFiles) => {
    const docStatus = checkDocumentsStatus(uploadedFiles, requiredDocuments)
    setFormValue(prevState => ({
      ...prevState,
      docstatus: docStatus,
    }))
  }

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
          <Typography
            variant="subtitle1"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px 15px",
              borderRadius: "20px",
              backgroundColor:
                empStatus === "Active"
                  ? "#43a04733"
                  : empStatus === "New"
                    ? "#cccccc"
                    : "#e5393533",
              color:
                empStatus === "Active"
                  ? "green"
                  : empStatus === "New"
                    ? "gray"
                    : "red",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
              fontWeight: "bold",
            }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor:
                  empStatus === "Active"
                    ? "green"
                    : empStatus === "New"
                      ? "gray"
                      : "red",
                marginRight: "10px",
              }}
            ></Box>
            {empStatus === "New" ? "New" : empStatus === "Active" ? "Active" : "Inactive"}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleNavigateToTrackHistory}>
            View Track History
          </Button>
        </Box>

        {/* row 1 */}
        <Box className="itemBox">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">PERSONAL INFORMATION</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={2} lg={2} xl={1}>
                    <div className="label">Emp ID</div>
                    <TextField variant="standard" name="empid" value={empid} onChange={handleChange} fullWidth disabled />
                  </Grid>
                  <Grid item xs={12} sm={8} md={5} lg={5} xl={3}>
                    <div className={`label ${formValidation && formValidation.name ? formValidation.name : ""}`}>Full Name *</div>
                    <TextField className={`TextField ${formValidation && formValidation.name ? formValidation.name : ""}`} variant="standard" name="name" value={name} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} lg={5} xl={3}>
                    <div className="label">E-mail</div>
                    <TextField variant="standard" name="email" value={email} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={5} md={3} lg={3} xl={2}>
                    <div className={`label ${formValidation && formValidation.contactuae ? formValidation.contactuae : ""}`}>Contact No. *</div>
                    <TextField className={`TextField ${formValidation && formValidation.contactuae ? formValidation.contactuae : ""}`} variant="standard" name="contactuae" value={contactuae} onChange={handleChange} fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span>+971</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7} md={3} lg={3} xl={2}>
                    <div className={`label ${formValidation && formValidation.dob ? formValidation.dob : ""}`}>Date of Birth *</div>
                    <ResponsiveDatePicker
                      name="dob"
                      className="employeeForm"
                      defValue={dob}
                      variant="standard"
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={2} lg={1} xl={1}>
                    <div className={`label ${formValidation && formValidation.age ? formValidation.age : ""}`}>Age *</div>
                    <TextField className={`TextField ${formValidation && formValidation.age ? formValidation.age : ""}`} variant="standard" name="age" value={age} onChange={handleChange} fullWidth disabled />
                  </Grid>
                  <Grid item xs={12} sm={7} md={4} lg={3} xl={2}>
                    <div className={`label ${formValidation && formValidation.nationality ? formValidation.nationality : ""}`}>Nationality *</div>
                    <Autocomplete
                      disablePortal
                      name="nationality"
                      value={nationality}
                      onChange={handleNationalityChange}
                      options={nationalities}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={122} sm={5} md={4} lg={2} xl={2}>
                    <div className={`label ${formValidation.gender ? formValidation.gender : ""}`}>Gender *</div>
                    <Autocomplete
                      disablePortal
                      name="gender"
                      value={gender}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "gender",
                            value: newValue || "",
                          },
                        });
                      }}
                      options={["Male", "Female"]}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                    <div className="label">Martial Status</div>
                    <Autocomplete
                      disablePortal
                      name="maritalstatus"
                      value={maritalstatus}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "maritalstatus",
                            value: newValue || "",
                          },
                        });
                      }}
                      options={["Single", "Married"]}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                    <div className="label">Religion</div>
                    <TextField variant="standard" name="religion" value={religion} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={4}>
                    <div className="label">Remarks</div>
                    <TextField variant="standard" name="remarks" value={remarks} onChange={handleChange} fullWidth />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} lg={8} xl={4}>
                    <div className="label">Upload Photo</div>
                    <TextField type="file" variant="standard" name="photoloc" onChange={handleFileChange} fullWidth />
                  </Grid>
                  <Grid item xs={9} sm={10} md={8} lg={1} xl={1}>
                    {folderid && uploadedImage?.length > 0 && (
                      <Link to={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${uploadedImage}`} target="_blank">
                        <Avatar
                          alt="Profile Photo"
                          src={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${uploadedImage}`}
                          sx={{ width: 100, height: 100, borderRadius: 0 }}
                        />
                      </Link>
                    )}
                  </Grid> */}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 2 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">PASSPORT & VISA INFORMATION</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport No.</div>
                    <TextField variant="standard" name="passportno" value={passportno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport Status</div>
                    <Autocomplete
                      disablePortal
                      name="passportstatus"
                      value={passportstatus}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "passportstatus",
                            value: newValue || "",
                          },
                        });
                      }}
                      options={["With Company", "Employee"]}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport Issuing Country</div>
                    <Autocomplete
                      disablePortal
                      name="passportissuingcountry"
                      value={passportissuingcountry}
                      onChange={handlePassportIssuingCountryChange}
                      options={nationalities}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport Issue State</div>
                    <TextField variant="standard" name="passportissuestate" value={passportissuestate} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Passport Expiry</div>
                    <ResponsiveDatePicker
                      name="passportexpiry"
                      className="employeeForm"
                      defValue={passportexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Sponsor Name</div>
                    {/* <TextField variant="standard" name="sponsorname" value={sponsorname} onChange={handleChange} fullWidth /> */}
                    <Dropdown
                      fieldName="sponsorname"
                      dropdownOptions={dropdownOptions.sponsorname}
                      setDropdownOptions={setDropdownOptions}
                      value={sponsorname}
                      onChange={handleDropdownChange}
                      tier={tier}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Labour Card Under</div>
                    <TextField variant="standard" name="labourcard" value={labourcard} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Contract Type</div>
                    <Autocomplete
                      disablePortal
                      name="contracttype"
                      value={contracttype}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "contracttype",
                            value: newValue || "",
                          },
                        });
                      }}
                      options={["Limited", "Labour Card"]}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa File No.</div>
                    <TextField variant="standard" name="visafileno" value={visafileno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">UID</div>
                    <TextField variant="standard" name="visaid" value={visaid} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Designation</div>
                    <TextField variant="standard" name="visadesignation" value={visadesignation} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Type</div>
                    <Autocomplete
                      disablePortal
                      name="visatype"
                      value={visatype}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "visatype",
                            value: newValue || "",
                          },
                        });
                      }}
                      options={["Skilled", "Unskilled"]}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Status</div>
                    <Autocomplete
                      disablePortal
                      name="visastatus"
                      value={visastatus}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "visastatus",
                            value: newValue || "",
                          },
                        });
                      }}
                      options={["Active", "Cancelled"]}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Expiry</div>
                    <ResponsiveDatePicker
                      name="visaexpiry"
                      className="employeeForm"
                      defValue={visaexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Visa Cancellation Date</div>
                    <ResponsiveDatePicker
                      name="visacancellation"
                      className="employeeForm"
                      defValue={visacancellation}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Emirates ID No.</div>
                    <TextField variant="standard" name="eidno" value={eidno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Work Permit No.</div>
                    <TextField variant="standard" name="workpermitnumber" value={workpermitnumber} onChange={handleChange} fullWidth />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Work Permit Start Date</div>
                    <ResponsiveDatePicker
                      name="workpermitstartdate"
                      className="employeeForm"
                      defValue={workpermitstartdate}
                      handleDate={handleChange}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Work Permit Expiry</div>
                    <ResponsiveDatePicker
                      name="workpermitexpiry"
                      className="employeeForm"
                      defValue={workpermitexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Personal Code (MOHRE)</div>
                    <TextField variant="standard" name="personalcodemohre" value={personalcodemohre} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Medical Insurance Provider</div>
                    <TextField variant="standard" name="medicalprovider" value={medicalprovider} onChange={handleChange} fullWidth>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Medical Insurance Card No.</div>
                    <TextField variant="standard" name="medicalcardno" value={medicalcardno} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Medical Insurance Expiry</div>
                    <ResponsiveDatePicker
                      name="medicalexpiry"
                      className="employeeForm"
                      defValue={medicalexpiry}
                      handleDate={handleChange}
                    />
                  </Grid>
                  {/* Additional fields */}
                  {formValue.nationality === "U.A.E" && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div className="label">GPSSA</div>
                        <TextField select variant="standard" name="gpssa" value={gpssa} onChange={handleChange} fullWidth>
                          <MenuItem value="123">123</MenuItem>
                          <MenuItem value="456">456</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div className="label">Insured No.</div>
                        <TextField
                          variant="standard"
                          name="insuredno"
                          value={insuredno}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div className="label">Insured Date</div>
                        <ResponsiveDatePicker
                          name="insureddate"
                          className="employeeForm"
                          defValue={insureddate}
                          handleDate={handleChange}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 3 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">EMPLOYMENT DETAILS</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Date of Join (Actual)</div>
                    <ResponsiveDatePicker
                      name="dateofjoin"
                      className="employeeForm"
                      defValue={dateofjoin}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">MOL Start Date</div>
                    <ResponsiveDatePicker
                      name="dateofjoinmol"
                      className="employeeForm"
                      defValue={dateofjoinmol}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Hired from</div>
                    <Autocomplete
                      disablePortal
                      name="hiredfrom"
                      value={hiredfrom}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: {
                            name: "hiredfrom",
                            value: newValue || "",
                          },
                        });
                      }}
                      options={["Outside", "Inside"]}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Office Location</div>
                    <TextField variant="standard" name="officeloc" value={officeloc} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Designation</div>
                    <TextField variant="standard" name="designation" value={designation} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Bank Name</div>
                    {/* <TextField variant="standard" name="bankname" value={bankname} onChange={handleChange} fullWidth /> */}
                    <Dropdown
                      fieldName="bankname"
                      dropdownOptions={dropdownOptions.bankname}
                      setDropdownOptions={setDropdownOptions}
                      value={bankname}
                      onChange={handleDropdownChange}
                      tier={tier}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Department</div>
                    <Dropdown
                      fieldName="dept"
                      dropdownOptions={
                        formValue.bankname
                          ? dropdownOptions.bankDeptMapping?.[formValue.bankname] || []
                          : []
                      }
                      setDropdownOptions={setDropdownOptions}
                      value={formValue.dept}
                      onChange={handleDropdownChange}
                      tier={tier}
                      bankDeptMapping={dropdownOptions.bankDeptMapping}
                      bankname={formValue.bankname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className="label">Line Manager</div>
                    <Dropdown
                      fieldName="linemanager"
                      dropdownOptions={dropdownOptions.linemanager}
                      setDropdownOptions={setDropdownOptions}
                      value={linemanager}
                      onChange={handleDropdownChange}
                      tier={tier}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <div className="label">Team Leader</div>
                    <TextField variant="standard" name="teamleader" value={teamleader} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Source Code</div>
                    <TextField variant="standard" name="sourcecode" value={sourcecode} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Last Working Day</div>
                    <ResponsiveDatePicker
                      name="lwd"
                      className="employeeForm"
                      defValue={lwd}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">Effect End Date</div>
                    <ResponsiveDatePicker
                      name="eed"
                      className="employeeForm"
                      defValue={eed}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                    <div className="label">Status Remarks</div>
                    <TextField variant="standard" name="statusremarks" value={statusremarks} onChange={handleChange} fullWidth />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 4 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">CONTACT DETAILS</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact Name (UAE)</div>
                    <TextField variant="standard" name="emergencycontactuaename" value={emergencycontactuaename} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact No. (UAE)</div>
                    <TextField variant="standard" name="emergencycontactuaeno" value={emergencycontactuaeno} onChange={handleChange} fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span>+971</span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Contact No. (Home Country)</div>
                    <TextField variant="standard" name="contacthome" value={contacthome} onChange={handleChange} fullWidth
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <span>{getCountryCode}</span>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact Name (Home Country)</div>
                    <TextField variant="standard" name="emergencycontacthomename" value={emergencycontacthomename} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Emergency Contact No. (Home Country)</div>
                    <TextField variant="standard" name="emergencycontacthomeno" value={emergencycontacthomeno} onChange={handleChange} fullWidth
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <span>{getCountryCode}</span>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <div className="label">Company SIM No.</div>
                    <TextField variant="standard" name="companysimnumber" value={companysimnumber} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">SIM Issue Date</div>
                    <ResponsiveDatePicker
                      name="simissuedate"
                      className="employeeForm"
                      defValue={simissuedate}
                      variant="standard"
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">SIM Last Date</div>
                    <ResponsiveDatePicker
                      name="simlastdate"
                      className="employeeForm"
                      defValue={simlastdate}
                      variant="standard"
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <div className="label">SIM Plan</div>
                    <TextField variant="standard" name="simplan" value={simplan} onChange={handleChange} fullWidth />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 5 */}
        {
          salaryEdit != "notallowed" && (
            <Box className="itemBox">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper className="itemPaper">
                    <h3 className="cardTitle">
                      SALARY DETAILS
                      <span className="updatebtn" style={{ paddingLeft: "20px" }}>
                        {
                          salaryEdit === "edit" &&
                          <Button onClick={incrementContract} disabled={loader} style={{ width: "150px", borderRadius: "20px" }}>
                            + Add Contract
                          </Button>
                        }
                      </span>
                    </h3>

                    <h3>Actual Salary Details</h3>
                    <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <div className="label">Actual Salary</div>
                        <TextField variant="standard" name="contractsalary" value={contractsalary} onChange={handleChange} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <div className="label">Account No.</div>
                        <TextField variant="standard" name="accnumber" value={accnumber} onChange={handleChange} fullWidth disabled={salaryEdit !== "edit"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <div className="label">IBAN</div>
                        <TextField variant="standard" name="iban" value={iban} onChange={handleChange} fullWidth disabled={salaryEdit !== "edit"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <div className="label">Salary Bank Name</div>
                        <TextField variant="standard" name="salarybankname" value={salarybankname} onChange={handleChange} fullWidth disabled={salaryEdit !== "edit"}
                        />
                      </Grid>
                    </Grid>

                    {/* Contracts */}
                    {formValue.contractupdate.map((contract, index) => (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                        <Paper className="position-relative">
                          {
                            salaryEdit === "edit" &&
                            <div className="closeButton" onClick={() => handleContractRemove(index)}>
                              x
                            </div>
                          }
                          <h3>Contract No. {formValue.contractupdate.length - index + 1}</h3>
                          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Contract Date</div>
                              <ResponsiveDatePicker
                                variant="standard"
                                className="employeeForm"
                                name={`contractupdate${index}.date`}
                                defValue={contract.date}
                                handleDate={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Basic Salary</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.basic`}
                                value={contract.basic}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Housing Allowance</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.housing`}
                                value={contract.housing}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Transport Allowance</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.transport`}
                                value={contract.transport}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Food Allowance</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.food`}
                                value={contract.food}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Mobile Allowance</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.mobile`}
                                value={contract.mobile}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Other Allowance</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.allowance`}
                                value={contract.allowance}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid>
                            <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Total Salary</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.total`}
                                value={contract.total}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                  readOnly: true
                                }}
                              />
                            </Grid>
                            {/* <Grid className="w-100" style={{ padding: 20 }} item xs={12} sm={6} md={3} lg={2} xl={2}>
                              <div className="label">Actual Salary</div>
                              <TextField
                                variant="standard"
                                name={`contractupdate${index}.contracts`}
                                value={contract.contracts}
                                onChange={handleContractUpdate(index)}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span>/- AED</span>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={salaryEdit !== "edit"}

                              />
                            </Grid> */}
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}

                    <h3>Contract No. 1</h3>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <div className="label">Basic Salary</div>
                        <TextField variant="standard" name="basicsalary" value={basicsalary} onChange={handleChange} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <div className="label">Housing Allowance</div>
                        <TextField variant="standard" name="housingallowance" value={housingallowance} onChange={handleChange} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <div className="label">Transport Allowance</div>
                        <TextField variant="standard" name="transportallowance" value={transportallowance} onChange={handleChange} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <div className="label">Food Allowance</div>
                        <TextField variant="standard" name="foodallowance" value={foodallowance} onChange={handleChange} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <div className="label">Mobile Allowance</div>
                        <TextField variant="standard" name="mobileallowance" value={mobileallowance} onChange={handleChange} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <div className="label">Other Allowance</div>
                        <TextField variant="standard" name="otherallowance" value={otherallowance} onChange={handleChange} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                        <div className="label">Total Salary</div>
                        <TextField variant="standard" name="totalsalary" value={totalsalary} onChange={handleChange} onBlur={handleBlur} fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span>/- AED</span>
                              </InputAdornment>
                            ),
                          }} disabled={salaryEdit !== "edit"}

                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

          )
        }

        {/* row 6 */}
        <Box className="itemBox">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className="itemPaper">
                <h3 className="cardTitle">BACKGROUND CHECK</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <div className="label">Previous Employer's Email</div>
                    <TextField variant="standard" name="previousempemail" value={previousempemail} onChange={handleChange} fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <div className="label">Verification Status</div>
                    <TextField select variant="standard" name="verificationstatus" value={verificationstatus} onChange={handleChange} fullWidth>
                      <MenuItem value="Sent">Sent</MenuItem>
                      <MenuItem value="In Process">In Process</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <div className="label">Email Sent Date</div>
                    <ResponsiveDatePicker
                      name="emailsenton"
                      className="employeeForm"
                      defValue={emailsenton}
                      handleDate={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                    <div className="label">Email Received Date</div>
                    <ResponsiveDatePicker
                      name="repliedon"
                      className="employeeForm"
                      defValue={repliedon}
                      handleDate={handleChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 7 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>
              <Paper sx={{ height: "400px" }} className="itemPaper">
                <h3 className="cardTitle">DOCUMENT UPLOADS</h3>
                <DragNDrop onDrop={handleDrop} uploadedFiles={tempUploadedFiles} setUploadedFiles={setTempUploadedDocs} handleRemoveFile={handleRemoveFile} />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={6}>
              <Paper className="itemPaper" sx={{
                height: "400px",
                padding: "30px",
                overflow: "hidden"
              }}>
                <h3 className="cardTitle" style={{ margin: "0", padding: "0" }}>EXISTING DOCUMENTS</h3>
                <TableWrapper sx={{ overflowY: "scroll", height: "300px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>File Name</TableCell>
                        <TableCell style={{ textAlign: "right" }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadedDocs && uploadedDocs.length > 0 && uploadedDocs.map((doc, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ margin: "0", padding: "0 16px" }}>
                            {folderid && (
                              <Link to={`https://fmapi.fintrekmarketing.com:2083/uploads/${folderid}/${doc.filename}`} target="_blank" style={{ color: "black", textDecoration: "none" }}>
                                {doc.filename}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell style={{ margin: "0", padding: "0 16px", textAlign: "right" }}>
                            {folderid && (
                              <IconButton style={{ color: "#2596BE" }} onClick={() => handleDeleteDocument(doc.filename)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableWrapper>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* row 8 */}
        <Box className="itemBox">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="itemPaper requiredDocs">
                <h3 className="cardTitle">REQUIRED DOCUMENTS</h3>
                <ul className="requiredDocsList">
                  {requiredDocuments.map((docKey) => {
                    const doc = documentMappingInitial[docKey]
                    const docName = doc?.name || docKey
                    const isUploaded = isDocumentUploaded(docName)
                    return (
                      <li className="requiredDocsItem" key={docName}>
                        <div className="textContainer">
                          {isUploaded ? (
                            <CheckCircleIcon style={{ color: "green", marginRight: "8px" }} className="docIcon" />
                          ) : (
                            <Box
                              className="docIcon"
                              sx={{
                                width: 24,
                                height: 24,
                                border: "1px solid #ccc",
                                borderRadius: "50%",
                                marginRight: "8px",
                              }}
                            />
                          )}
                          {docName}
                        </div>
                        <Button
                          className="removeButton"
                          onClick={() => removeRequiredDocument(docKey)}
                        >
                          Remove
                        </Button>
                      </li>
                    )
                  })}
                </ul>
                <Box className="addDocBox">
                  <TextField
                    variant="standard"
                    placeholder="Add new document"
                    value={newDoc}
                    onChange={(e) => setNewDoc(e.target.value)}
                    sx={{ marginRight: "8px", flexGrow: 1 }}
                    inputProps={{ style: { textTransform: "none" } }}
                  />
                  <Button className="add-list" onClick={() => addRequiredDocument(newDoc)}>
                    Add
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box className="itemBox" style={{ display: "flex", gap: "20px" }}>
          <div className="updatebtn" style={{ textAlign: "center" }}>
            <Button
              onClick={handleSubmit}
              disabled={loader}
              style={{
                backgroundColor: "#1976d2",
                color: "white",
              }}
            >
              {loader ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "SAVE CHANGES"
              )}
            </Button>
          </div>
        </Box>

      </Box >

      {/* Salary Confirmation Modal */}
      <Modal open={confirmationModal} onClose={handleCancelChange} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description">
        <Paper className="floatingPaper">
          <Typography id="confirmation-modal-title" variant="h6" component="h2">
            Confirm Salary Change
          </Typography>
          <Typography id="confirmation-modal-description" sx={{ mt: 2, color: "gray" }}>
            Are you sure you want to change the total salary?
          </Typography>
          <div style={{ display: "flex", marginTop: "20px", width: "370px", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancelChange}
              style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}>
              No
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                width: "60px",
                height: "35px",
              }}
              autoFocus
              onClick={handleConfirmChange}>
              Yes
            </Button>
          </div>
        </Paper>
      </Modal>

      {/* Overwrite Modal */}
      <Modal open={overwriteModal} onClose={closeOverwiteModal} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description">
        <Paper className="floatingPaper">
          <Typography id="confirmation-modal-title" variant="h6" component="h2">
            Confirm Overwrite?
          </Typography>
          <Typography id="confirmation-modal-description" sx={{ mt: 2, color: "gray" }}>
            One or more file(s) that you are trying to upload already exists. Are you sure you want to overwrite them?
          </Typography>
          <div style={{ display: "flex", marginTop: "10px", width: "370px", justifyContent: "space-between" }}>
            <Button variant="contained" onClick={closeOverwiteModal} style={{ backgroundColor: "#FF0000", color: "white", width: "60px", height: "35px" }}>
              No
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                width: "60px",
                height: "35px",
              }}
              onClick={() => { performSubmit(); closeOverwiteModal(); }}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Paper>
      </Modal>
    </>
  )
}