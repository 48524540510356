import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, Divider, Modal } from '@mui/material'
import { fetchEmpVisaDetails } from "../../Actions/commonController"
import moment from "moment"

export default function VisaDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [visaDetails, setVisaDetails] = useState(null)

  const fetchData = async () => {
    try {
      let APIData = {
        empid: empId,
      }
      if (empId) {
        setLoader(true)
        let { data, status } = await fetchEmpVisaDetails(APIData)
        if (status === "success") setVisaDetails(data[0])
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching personal details data:", error)
    }
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId])

  const FormatDate = (date) => {
    return moment(date).format("Do MMMM, YYYY")
  }

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Visa Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          <p id="keep-mounted-modal-description" className="field">
            Date of Join (MOL)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.dateofjoinmol ? FormatDate(visaDetails.dateofjoinmol) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Work Permit Number
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.workpermitnumber ? visaDetails.workpermitnumber : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Work Permit Expiry
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.workpermitexpiry ? FormatDate(visaDetails.workpermitexpiry) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Personal Code (MOHRE)
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.personalcodemohre ? visaDetails.personalcodemohre : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Contract Type
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.contracttype ? visaDetails.contracttype : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Sponsor Name
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.sponsorname ? visaDetails.sponsorname : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Labour Card Under
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.labourcard ? visaDetails.labourcard : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            UID
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.visaid ? visaDetails.visaid : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Visa File Number
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.visafileno ? visaDetails.visafileno : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Visa Designation
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.visadesignation ? visaDetails.visadesignation : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Visa Type
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.visatype ? visaDetails.visatype : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Visa status
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.visastatus ? visaDetails.visastatus : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Visa Expiry
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.visaexpiry ? FormatDate(visaDetails.visaexpiry) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Visa Cancellation Date
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.visacancellation ? FormatDate(visaDetails.visacancellation) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            EID No.
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              visaDetails?.eidno ? visaDetails.eidno : "-"
            )}
          </h4>
        </div>
      </div>
    </Modal>
  )
}

VisaDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  empId: PropTypes.string.isRequired,
}

const Backdrop = (props) => {
  const { open, className, ...other } = props;

  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      {...other}
    />
  );
};

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
}