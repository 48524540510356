import React, { useState } from "react"
import {
  Grid,
  Paper,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  styled,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import LaunchIcon from '@mui/icons-material/Launch'
import {
  applySalaryCert,
  fetchEmployeesForSalaryCertificate,
  fetchPendingSalaryCertificates,
} from "../Actions/commonController"
import { useAuth } from "../Context/authContext"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import EmployeeSelectionForm from "./Tables/EmployeeTables/EmployeeSelectionForm"
import nationalities from "../json/nationalities.json"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "5px",
  fontFamily: "Open Sans",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
}))

const RowContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const Divider = styled("hr")({
  height: "4px",
  borderTop: "1px solid #ccc",
  background: "linear-gradient(to right, #00cc00, #00ff00)",
})

export default function DashboardEmployees() {

  const [salaryCertOpen, setSalaryCertOpen] = useState(false)
  const [selectedEmpId, setSelectedEmpId] = useState("")
  const [toWhom, setToWhom] = useState("")
  const [country, setCountry] = useState("")
  const [reason, setReason] = useState("")
  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingCertificates, setLoadingCertificates] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { eid } = useAuth()
  const navigate = useNavigate()

  const handleClickOpen = async () => {
    setSalaryCertOpen(true)
    await fetchEmployees()
  }

  const handleClose = () => {
    setSalaryCertOpen(false)
    setSelectedEmpId("")
    setToWhom("")
    setCountry("")
    setReason("")
  }

  const fetchEmployees = async () => {
    try {
      const { status, data } = await fetchEmployeesForSalaryCertificate({ empid: eid });
      if (status === "success") {
        setEmployees(data);
      } else {
        toast.error("Failed to fetch employee list.");
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employee list.");
    }
  };

  const handleConfirm = async () => {
    try {
      if (!selectedEmpId || !reason || !toWhom || !country) {
        toast.error("Please fill in all fields.")
        return
      }
      let APIData = {
        empid: selectedEmpId,
        reason: reason,
        towhom: toWhom,
        country: country,
      }
      setLoadingCertificates(true)
      const { status } = await applySalaryCert(APIData)
      if (status === "success") {
        toast.success("Salary certificate request sent successfully!")
        handleClose()
      } else {
        toast.error("Request is already placed!")
      }
    } catch (error) {
      toast.error("Error requesting salary certificate! (Contact administrator")
    }
    setLoadingCertificates(false)
  }

  const handleOpenLeaveFormModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseLeaveFormModal = () => {
    setIsModalOpen(false)
  }
  
  return (
    <Box>
      {/* row 1 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              {/* <div className="cards">
                <h2 className="p-0">TOTAL NO. OF EMPLOYEES</h2>
                <h4>1000</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropUpIcon /> 4.6%
                  </div>
                  <p>Today</p>
                </div>
              </div> */}
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              {/* <div className="cards">
                <h2 className="p-0">MONTHLY NEW HIRES</h2>
                <h4>20</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropDownIcon /> 10%
                  </div>
                  <p>Month</p>
                </div>
              </div> */}
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              {/* <div className="cards">
                <h2 className="p-0">DEPARTMENTS</h2>
                <h4>5</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropUpIcon /> 2.3%
                  </div>
                  <p>Today</p>
                </div>
              </div> */}
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              {/* <div className="cards">
                <h2 className="p-0">NO. OF OPEN POSITIONS</h2>
                <h4>6</h4>
                <div className="dividerParent">
                  <Divider />
                </div>
                <div className="percentage">
                  <div className="box">
                    <ArrowDropDownIcon /> 2.3%
                  </div>
                  <p>Today</p>
                </div>
              </div> */}
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* row 2 */}
      <RowContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                {/* <h2 className="p-0">LEAVE REQUEST UPDATES</h2> */}
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} md={3}>
            <Item sx={{ padding: "25px 15px" }}>
              <div className="cards">
                <Box display="flex" alignItems="center">
                  <LaunchIcon sx={{ color: "#1976d2", fontSize: "25px", marginRight: "10px" }} />
                  <h2 className="p-0" style={{ margin: 0 }}>QUICK LINKS</h2>
                </Box>
                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleOpenLeaveFormModal}
                  >
                    Request Leave Application
                  </Button>
                  <EmployeeSelectionForm open={isModalOpen} onClose={handleCloseLeaveFormModal} />
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      backgroundColor: "#2596BE"
                    }}
                    onClick={handleClickOpen}
                  >
                    Request Salary Certificate
                  </Button>
                </span>
              </div>
            </Item>
          </Grid>
        </Grid>
      </RowContainer>

      {/* Request Salary Certificate Button */}
      <Dialog open={salaryCertOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Request Salary Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill out the form to request a salary certificate.
          </DialogContentText>

          <Select
            value={selectedEmpId}
            onChange={(e) => setSelectedEmpId(e.target.value)}
            fullWidth
            displayEmpty
            sx={{ mt: 1, mb: 1 }}
          >
            <MenuItem value="" disabled>Select Employee</MenuItem>
            {employees.map((employee) => (
              <MenuItem key={employee.EmployeeID} value={employee.EmployeeID}>
                {employee.EmployeeName}
              </MenuItem>
            ))}
          </Select>
          <Box display="flex" gap={2} sx={{ mt: 1, mb: 1 }}>
            <TextField
              label="To"
              value={toWhom}
              onChange={(e) => setToWhom(e.target.value)}
              fullWidth
              sx={{ flex: 1 }}
            />
            <Select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              displayEmpty
              fullWidth
              sx={{ flex: 1 }}
            >
              <MenuItem value="" disabled>Select Country</MenuItem>
              {nationalities.map((nat, index) => (
                <MenuItem key={index} value={nat}>{nat}</MenuItem>
              ))}
            </Select>
          </Box>
          <TextField
            label="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            multiline
            rows={3}
            sx={{ mt: 1, mb: 1 }}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "0px 20px 20px 20px" }}>
          <Button
            sx={{ backgroundColor: "red", color: "white", "&:hover": { backgroundColor: "darkred" }, width: "80px", height: "35px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ backgroundColor: "#2596BE", color: "white", "&:hover": { backgroundColor: "#1c7da0" }, width: "80px", height: "35px" }}
            onClick={handleConfirm}
            disabled={loadingCertificates}
          >
            {loadingCertificates ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

    </Box >
  )
}
