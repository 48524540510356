import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import CloseIcon from "@mui/icons-material/Close"
import { Modal, CircularProgress, Divider } from "@mui/material"
import { fetchEmpSalaryDetails } from "../../Actions/commonController"
import moment from "moment"

export default function SalaryDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [salaryDetails, setSalaryDetails] = useState(null)
  const [totalSalary, setTotalSalary] = useState("0.00")
  const [contractDetails, setContractDetails] = useState(null)

  const fetchData = async () => {
    try {
      let APIData = {
        empid: empId,
      }
      if (empId) {
        setLoader(true)
        let { data, status } = await fetchEmpSalaryDetails(APIData)
        if (status === "success") {
          setSalaryDetails(data[0])
          calculateTotalSalary(data[0])

          const formattedContracts = data[0]?.contractupdate
            ? JSON.parse(data[0].contractupdate)
              .map((contract) => ({
                ...contract,
                date: moment(contract.date).format("Do MMMM, YYYY"),
                rawDate: contract.date,
              }))
              .reverse()
            : []
          setContractDetails({
            ...data[0],
            contractupdate: formattedContracts,
          })
        }
        setLoader(false)
      }
    } catch (error) {
      console.error("Error fetching personal details data:", error)
    }
  }

  const calculateTotalSalary = (details) => {
    const total = (
      parseFloat(details.basicsalary || 0) +
      parseFloat(details.housingallowance || 0) +
      parseFloat(details.transportallowance || 0) +
      parseFloat(details.foodallowance || 0) +
      parseFloat(details.mobileallowance || 0) +
      parseFloat(details.otherallowance || 0)
    ).toFixed(2)
    setTotalSalary(total)
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId, open])

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Salary Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          {loader ? (
            <CircularProgress size={24} style={{ color: "black" }} />
          ) : (
            <>
              <p id="keep-mounted-modal-description" className="field">
                Actual Salary
              </p>
              <h4>{salaryDetails?.contractsalary ? `${salaryDetails.contractsalary} /-AED` : "0 /-AED"}</h4>

              <p id="keep-mounted-modal-description" className="field">
                Account Number
              </p>
              <h4>{salaryDetails?.accnumber ? salaryDetails.accnumber : "-"}</h4>

              <p id="keep-mounted-modal-description" className="field">
                IBAN
              </p>
              <h4>{salaryDetails?.iban ? salaryDetails.iban : "-"}</h4>

              <p id="keep-mounted-modal-description" className="field">
                Bank Name
              </p>
              <h4>{salaryDetails?.salarybankname ? salaryDetails.salarybankname : "-"}</h4>

              {/* Contract Updates */}
              {contractDetails?.contractupdate?.length > 0 &&
                contractDetails.contractupdate.map((contract, index) => (
                  <div key={index} className="division">
                    <h3 className="centered-heading">
                      Contract No. {contractDetails.contractupdate.length - index + 1}
                    </h3>
                    <p id="keep-mounted-modal-description" className="field">Contract Date:</p>
                    <h4>{contract.date}</h4>
                    <p id="keep-mounted-modal-description" className="field">Basic Salary:</p>
                    <h4>{contract.basic} /- AED</h4>
                    <p id="keep-mounted-modal-description" className="field">Housing Allowance:</p>
                    <h4>{contract.housing} /- AED</h4>
                    <p id="keep-mounted-modal-description" className="field">Transport Allowance:</p>
                    <h4>{contract.transport} /- AED</h4>
                    <p id="keep-mounted-modal-description" className="field">Food Allowance:</p>
                    <h4>{contract.food} /- AED</h4>
                    <p id="keep-mounted-modal-description" className="field">Mobile Allowance:</p>
                    <h4>{contract.mobile} /- AED</h4>
                    <p id="keep-mounted-modal-description" className="field">Other Allowance:</p>
                    <h4>{contract.allowance} /- AED</h4>
                    <p id="keep-mounted-modal-description" className="field">Total Salary:</p>
                    <h4>{contract.total} /- AED</h4>
                    {/* <p id="keep-mounted-modal-description" className="field">Contract Salary:</p>
                    <h4>{contract.contracts} /- AED</h4> */}
                  </div>
                ))
              }

              {/* Contract 1 */}
              <div className="division">
                <h3 className="centered-heading">Contract No. 1</h3>
                <p id="keep-mounted-modal-description" className="field">Basic Salary</p>
                <h4>{salaryDetails?.basicsalary ? `${salaryDetails.basicsalary} /-AED ` : "0 /-AED"}</h4>
                <p id="keep-mounted-modal-description" className="field">Housing Allowance</p>
                <h4>{salaryDetails?.housingallowance ? `${salaryDetails.housingallowance} /-AED` : "0 /-AED"}</h4>
                <p id="keep-mounted-modal-description" className="field">Transportation Allowance</p>
                <h4>{salaryDetails?.transportallowance ? `${salaryDetails.transportallowance} /-AED` : "0 /-AED"}</h4>
                <p id="keep-mounted-modal-description" className="field">Food Allowance</p>
                <h4>{salaryDetails?.foodallowance ? `${salaryDetails.foodallowance} /-AED` : "0 /-AED"}</h4>
                <p id="keep-mounted-modal-description" className="field">Mobile Allowance</p>
                <h4>{salaryDetails?.mobileallowance ? `${salaryDetails.mobileallowance} /-AED` : "0 /-AED"}</h4>
                <p id="keep-mounted-modal-description" className="field">Other Allowance</p>
                <h4>{salaryDetails?.otherallowance ? `${salaryDetails.otherallowance} /-AED` : "0 /-AED"}</h4>
                <p id="keep-mounted-modal-description" className="field">Total Salary</p>
                <h4>{totalSalary ? `${totalSalary} /-AED` : "0 /-AED"}</h4>
              </div>
            </>
          )}
        </div>

      </div>
    </Modal>
  )
}

const Backdrop = (props) => {
  const { open, className, ...other } = props

  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      {...other}
    />
  )
}

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
}