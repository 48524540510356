import React, { useEffect, useState } from "react"
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material"
import ResponsiveDatePicker from "../DatePicker/ResponsiveDatePicker"
import ClearIcon from '@mui/icons-material/Clear'
import moment from "moment"

const FIELD_LABELS = {
  empid: 'Emp ID',
  name: 'Name',
  email: 'Email',
  gender: 'Gender',
  nationality: 'Nationality',
  dob: 'Date of Birth',
  age: "Age",
  passportno: 'Passport No.',
  passportstatus: "Passport Status",
  passportexpiry: 'Passport Expiry',
  passportissuingcountry: 'Passport Issuing Country',
  passportissuestate: 'Passport Issue State',
  contactuae: 'Contact UAE',
  maritalstatus: 'Marital Status',
  religion: 'Religion',
  hiredfrom: 'Hired From',
  dateofjoin: 'Date of Join (Actual)',
  designation: 'Designation',
  officeloc: 'Office Location',
  bankname: 'Bank Name',
  dept: 'Department',
  // division: 'Division',
  sourcecode: 'Source Code',
  linemanager: 'Line Manager',
  teamleader: 'Team Leader',
  visaid: 'UID',
  dateofjoinmol: 'MOL Start Date',
  workpermitnumber: 'Work Permit Number',
  // workpermitstartdate: "Work Permit Start Date",
  workpermitexpiry: 'Work Permit Expiry',
  personalcodemohre: 'Personal Code (MOHRE)',
  contracttype: 'Contract Type',
  sponsorname: 'Sponsor Name',
  labourcard: 'Labour Card Under',
  visafileno: 'Visa File No.',
  visadesignation: 'Visa Designation',
  visatype: 'Visa Type',
  visastatus: 'Visa Status',
  visacancellation: 'Visa Cancellation Date',
  visaexpiry: 'Visa Expiry',
  eidno: 'Emirates ID No.',
  gpssa: "GPSSA",
  insuredno: "Insured No.",
  insureddate: 'Insured Date',
  contacthome: 'Contact Home',
  emergencycontactuaename: 'Emergency Contact UAE Name',
  emergencycontactuaeno: 'Emergency Contact UAE No.',
  emergencycontacthomename: 'Emergency Contact Home Name',
  emergencycontacthomeno: 'Emergency Contact Home No.',
  companysimnumber: 'Company SIM Number',
  simissuedate: 'SIM Issue Date',
  simlastdate: 'SIM Last Date',
  simplan: 'SIM Plan',
  empstatus: 'Employee Status',
  // absconded: "Absconded",
  statusremarks: 'Status Remarks',
  lwd: 'Last Working Day',
  eed: 'Effective End Date',
  medicalprovider: 'Medical Provider',
  medicalexpiry: 'Medical Expiry',
  medicalcardno: 'Medical Card No.',
  basicsalary: 'Basic Salary',
  housingallowance: 'Housing Allowance',
  transportallowance: 'Transport Allowance',
  foodallowance: 'Food Allowance',
  mobileallowance: 'Mobile Allowance',
  otherallowance: 'Other Allowance',
  totalsalary: 'Total Salary',
  contractsalary: 'Contract Salary',
  accnumber: 'Bank Account Number',
  iban: 'IBAN',
  salarybankname: 'Salary Bank Name',
  docstatus: 'Document Status',
  remarks: 'Remarks',
  createdAt: 'Created At',
  createdBy: 'Created By',
  previousempemail: 'Previous Employee Email',
  verificationstatus: 'Verification Status',
  emailsenton: 'Email Sent On',
  repliedon: 'Email Replied On'
}

const dateFields = [
  'dob',
  'passportexpiry',
  'dateofjoin',
  'dateofjoinmol',
  // 'workpermitstartdate',
  'workpermitexpiry',
  'visaexpiry',
  'lwd',
  'eed',
  'medicalexpiry',
  'visacancellation',
  'simissuedate',
  'simlastdate',
  'insureddate',
  'createdAt',
  'emailsenton',
  'repliedon'
]

const stringOperators = [
  'equals',
  'contains',
  'startsWith',
  'endsWith',
  'isEmpty',
  'isNotEmpty',
]

const dateOperators = [
  'is',
  'before',
  'after',
  'between',
]

const formatOperator = (operator) => {
  return operator.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
}

const CustomFilterPanel = ({ filters, setFilters }) => {

  useEffect(() => {
  }, [filters])

  const removeFilter = (index) => {
    if (filters.length > 1) {
      const newFilters = [...filters]
      newFilters.splice(index, 1)
      setFilters(newFilters)
    } else {
      setFilters([{ field: '', operator: '', value: '' }])

    }
  }

  const handleFilterChange = (index, key, value) => {
    const newFilters = [...filters]
    newFilters[index][key] = value
    setFilters(newFilters)
  }

  const renderValueInput = (filter, index) => {
    if (dateFields.includes(filter.field)) {
      if (filter.operator === "between") {
        return (
          <Box sx={{ display: "flex", alignItems: "center", paddingTop: "17px", maxWidth: "400px" }}>
            <ResponsiveDatePicker
              defValue={filter.value[0] ? moment(filter.value[0], 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}
              handleDate={(e) => handleFilterChange(index, "value", [moment(e.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD'), filter.value[1]])}
              format="DD/MM/YYYY"
              className="employeeForm"
            />
            <span> to </span>
            <ResponsiveDatePicker
              defValue={filter.value[1] ? moment(filter.value[1], 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}
              handleDate={(e) => handleFilterChange(index, "value", [filter.value[0], moment(e.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')])}
              format="DD/MM/YYYY"
              className="employeeForm"
            />
          </Box>
        )
      }
      return (
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: "17px", maxWidth: "200px" }}>
          <ResponsiveDatePicker
            defValue={filter.value ? moment(filter.value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}
            handleDate={(e) => handleFilterChange(index, "value", moment(e.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD'))}
            format="DD/MM/YYYY"
            className="employeeForm"
          />
        </Box>
      )
    }
    return (
      <TextField
        sx={{ minWidth: 100, lineHeight: "-0.5em !important" }}
        className="value"
        label="Value"
        variant="standard"
        value={filter.value}
        onChange={(e) => handleFilterChange(index, "value", e.target.value)}
        disabled={filter.operator === "isEmpty" || filter.operator === "isNotEmpty"}
      />
    )
  }


  return (
    <Box className="custom-filter-panel">
      {filters.map((filter, index) => (
        <Box key={index} className="filter-row">
          <IconButton onClick={() => removeFilter(index)} size="small" sx={{ marginTop: "20px" }}>
            <ClearIcon />
          </IconButton>
          <FormControl sx={{ minWidth: 120, textAlign: "left" }}>
            <InputLabel sx={{ textAlign: "left", left: "-15px !important", lineHeight: "2em !important" }}>Field</InputLabel>
            <Select
              value={filter.field}
              onChange={(e) => handleFilterChange(index, 'field', e.target.value)}
              label="Field"
              variant="standard"
              sx={{ textAlign: "left" }}
            >
              {Object.keys(FIELD_LABELS).map((key) => (
                <MenuItem key={key} value={key}>
                  {FIELD_LABELS[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 120, textAlign: "left" }}>
            <InputLabel sx={{ textAlign: "left", left: "-15px !important", lineHeight: "2em !important" }}>Operator</InputLabel>
            <Select
              value={filter.operator}
              onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
              label="Operator"
              variant="standard"
              sx={{ textAlign: "left" }}
            >
              {(dateFields.includes(filter.field) ? dateOperators : stringOperators).map((operator) => (
                <MenuItem key={operator} value={operator}>
                  {formatOperator(operator)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {renderValueInput(filter, index)}
        </Box>
      ))}
    </Box>
  )
}

export default CustomFilterPanel