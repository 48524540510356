import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import CloseIcon from "@mui/icons-material/Close"
import { Modal, CircularProgress, Divider } from "@mui/material"
import { fetchEmpDetails } from "../../Actions/commonController"
import moment from "moment"

export default function EmployeeDetailsModal({ open, onClose, empId }) {
  const [loader, setLoader] = useState(false)
  const [empDetails, setEmpDetails] = useState(null)

  const fetchData = async () => {
    try {
      let APIData = {
        empid: empId,
      }
      if (empId) {
        setLoader(true)
        let { data, status } = await fetchEmpDetails(APIData)
        if (status === "success") setEmpDetails(data[0])
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      console.error("Error fetching personal details data:", error)
    }
  }

  useEffect(() => {
    if (open) fetchData()
  }, [empId, open])

  const formatDate = (date) => {
    return moment(date).format("Do MMMM, YYYY")
  }

  return (
    <Modal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={onClose}
      className="modal"
      keepMounted
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2 id="keep-mounted-modal-title" className="name">
            Employee Details
          </h2>
          <button
            type="button"
            onClick={onClose}
            className="close-icon"
          >
            <CloseIcon />
          </button>
        </div>
        <Divider className="divider" />
        <div className="modalBody">
          <p id="keep-mounted-modal-description" className="field">
            Employee Status
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              empDetails?.empstatus ? empDetails.empstatus : "-"
            )}
          </h4>

          {/* <p id="keep-mounted-modal-description" className="field">
            Absconded
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              empDetails?.absconded ? "Yes" : "-"
            )}
          </h4> */}

          <p id="keep-mounted-modal-description" className="field">
            Status Remarks
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              empDetails?.statusremarks ? empDetails.statusremarks : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Effective End Date
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              empDetails?.eed ? formatDate(empDetails.eed) : "-"
            )}
          </h4>

          <p id="keep-mounted-modal-description" className="field">
            Last Working Day
          </p>
          <h4>
            {loader ? (
              <CircularProgress size={24} style={{ color: "black" }} />
            ) : (
              empDetails?.lwd ? formatDate(empDetails.lwd) : "-"
            )}
          </h4>
        </div>
      </div>
    </Modal>
  )
}

const Backdrop = (props) => {
  const { open, className, ...other } = props;

  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      {...other}
    />
  );
};

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};